import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import { getText } from 'helpers/text-helper';
import { levels, logMessage } from 'helpers/log-helper';
import './cgl-logo.scss';

const CGLLogo = ({languageId}) => {
	/**
	 * Handles going to external site
	 */
	const handleGoToExternal = () => {
		logMessage(levels.INFO, getText('session_userNavigationGamelabLogo', languageId));
		window.open(appConfig.cglUrl);
	};

	return (
		<div className='CGLLogo' onClick={() => {handleGoToExternal();}}/>
	);
};

CGLLogo.propTypes = {
	languageId: PropTypes.string.isRequired,
};

export default CGLLogo;