import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'components/app';
import appConfig from 'config/app.config';
import { 
	createRoutesFromChildren, 
	matchRoutes, 
	Routes, 
	useLocation, 
	useNavigationType 
} from 'react-router-dom';
import { 
	createReactRouterV6Options, 
	getWebInstrumentations, 
	initializeFaro, 
	ReactIntegration
} from '@grafana/faro-react';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { getText } from 'helpers/text-helper';
import './index.scss';

// We dont initialize for demo server as we dont log on demo server
if (appConfig.env !== 'demo') {
	initializeFaro({
		url: 'https://faro-collector-prod-eu-north-0.grafana.net/collect/49b12420319f7dd924b70d6b9ca10569',
		app: {
			name: getText('general_title', 'en'),
			version: '1.0.0',
			environment: appConfig.env,
		},

		instrumentations: [
			// Mandatory, omits default instrumentations otherwise.
			...getWebInstrumentations(),

			// Tracing package to get end-to-end visibility for HTTP requests.
			new TracingInstrumentation(),

			// React integration for React applications.
			new ReactIntegration({
				router: createReactRouterV6Options({
					createRoutesFromChildren,
					matchRoutes,
					Routes,
					useLocation,
					useNavigationType,
				}),
			}),
		],
	});
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<App />
);