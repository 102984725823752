import PropTypes from 'prop-types';
import './input.scss';
import './input-cpanel.scss';
import './input-admin.scss';
import './input-facilitator.scss';

const Input = ({
	isReadOnly = false, 
	id, 
	type = 'text', 
	name, 
	value, 
	placeholder, 
	accept,
	autoComplete, 
	classes, 
	onChange, 
	onClick
}) => {
	
	/* Add custom classes */
	let className = 'Input ' + type + (isReadOnly ? ' readOnly ' : '');
	if (classes && classes.length > 0) {
		classes.forEach((c) => {if (c.length > 0) className = className + ' ' + c;});
	}

	/**
	 * Handle onChange
	 * @param {object} event 
	 */
	const handleOnChange = (event) => {
		if (onChange && !isReadOnly) {
			onChange(event);
		}
	};

	/**
	 * Handle onClick
	 * @param {object} event 
	 */
	const handleOnClick = (event) => {
		if (onClick) {
			onClick(event);
		}
	};

	if (type === 'file' && id && value) {
		
		return (
			<div className={className}>
				<label htmlFor={id}>{value}</label>
				<input id={id} accept={accept} type={type} onChange={(event) => {handleOnChange(event);}} />
			</div>
		);
	}

	return (
		<input
			className={className}
			type={type}
			name={name ? name : ''}
			placeholder={placeholder ? placeholder : ''}
			autoComplete={autoComplete ? autoComplete : 'off'}
			value={value !== null && value !== undefined ? value : ''}
			onChange={(event) => {handleOnChange(event);}}
			onClick={(event) => {event.stopPropagation(); handleOnClick(event);}}
		/>
	);
};



Input.propTypes = {
	isReadOnly: PropTypes.bool,
	id: PropTypes.string,
	type: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	placeholder: PropTypes.string,
	accept: PropTypes.string,
	autoComplete: PropTypes.string,
	classes: PropTypes.array,	
	onChange: PropTypes.func,
	onClick: PropTypes.func,
};

export default Input;