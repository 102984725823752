const sessionUiTexts = {
	userNavigationGamelabLogo: {
		da: 'User navigating to company website using GameLab logo.',
		en: 'User navigating to company website using GameLab logo.',
		se: `User navigating to company website using GameLab logo.`,
		de: `User navigating to company website using GameLab logo.`,
		pl: `User navigating to company website using GameLab logo.`,
		ro: `User navigating to company website using GameLab logo.`,
		ua: `User navigating to company website using GameLab logo.`,
	},
};

export {
	sessionUiTexts
};