const landingPageGalleryData = [
	{
		id: 'useScenarios',
		textData: {
			title: {
				da: 'Mange brugsscenarier',
				en: 'Many different uses'
			},
			text: {
				da: 'Vores spil kan bruges til at sikre compliance, til onboarding og til kampagner som skal ændre adfærd.',
				en: 'Our games can be used to create compliance, for onboarding, and in campaigns aiming to change behaviour.'
			}
		},
		imageId: 'computer'
	},
	{
		id: 'e-learning',
		textData: {
			title: {
				da: 'E-learning+',
				en: 'E-learning+'
			},
			text: {
				da: 'Vi laver dynamisk scenariebaseret træning. Alle vores materialer er spilbaserede og derfor både sjovere og mere effektive end traditionel e-læring.',
				en: 'We create dynamic scenario based training. All our materials are game based which makes them both more engaging and effective than traditional e-learning.'
			}
		},
		imageId: 'mobile'
	},
	{
		id: 'experts',
		textData: {
			title: {
				da: 'Eksperter i andre folks faglighed',
				en: `Experts in other people's work`
			},
			text: {
				da: 'Vores træningsspil laves altid i tæt samarbejde med vores kunder. Derfor kan vi lave træning i kompleks faglighed som hygiejne, maskinsikkerhed, informationssikkerhed og meget andet.',
				en: 'Our training games are always made i close collaboration with our clients. That way we can make training in complex domains like hygiene, machine safety, information safety, and much more.'
			}
		},
		imageId: 'tablet'
	}
];

const bookDemoData = {
	title: {
		da: 'Book en demo',
		en: 'Book a demo'
	},
	telephoneHeader: {
		da: 'Eller ring direkte til os:',
		en: 'Or call us directly:'
	},
	telephoneShort: {
		da: 'Tlf.:',
		en: 'Tel.:'
	},
	instructions: {
		da: 'Udfyld formularen, så vil en af vores konsulenter kontakte dig.',
		en: 'Fill out the form to have one of our consultants contact you.'
	},
	textPointsHeader: {
		da: 'Hvad kan vi hjælpe med:',
		en: 'What we can help with:'
	},
	textPoints: [
		{
			da: 'At sikre compliance i forhold til fx sikkerhed eller hygiejne.',
			en: 'Compliance with safety or hygiene standards.'
		},
		{
			da: 'Onboarding af nye medarbejdere.',
			en: 'Onboarding new employees.'
		},
		{
			da: 'Kampagner til adfærdsændring.',
			en: 'Campaigns for behaviour change.'
		}
	],
	inputFields: [
		{
			id: 'name',
			isRequired: true,
			text: {
				da: 'Fornavn',
				en: 'First Name'
			}
		},
		{
			id: 'email',
			isRequired: true,
			text: {
				da: 'E-mail',
				en: 'E-mail'
			}
		},
		{
			id: 'company',
			isRequired: true,
			text: {
				da: 'Virksomhed',
				en: 'Company'
			}
		},
		{
			id: 'phone',
			isRequired: true,
			text: {
				da: 'Telefon',
				en: 'Phone'	
			}
		},
	],
	inputButton: {
		da: 'Book demo',
		en: 'Book demo'
	},
	feedbackText: {
		da: 'Tak for din interesse! Vi ser frem til at snakke med dig.',
		en: 'Thank you for your interest! We look forward to speaking with you.',
	}
}


export {
	landingPageGalleryData,
	bookDemoData
}