/**
 * Check if an object is an array
 * @param {object} obj
 * @returns 
 */
const checkIfObjectIsArray = (obj) => {
	if (typeof obj === 'object') {
		return Object.prototype.toString.call(obj) === '[object Array]'; 
	}
	return false;
};

/**
 * Check if an object is empty
 * @param {object} obj 
 */
const checkIfObjectIsEmpty = (obj) => {
	const objectIsEmpty = (
		obj &&
    Object.keys(obj).length === 0 &&
    obj.constructor === Object
	);
	return objectIsEmpty;
};

/**
 * Compares any two values for equality (takes arrays into consideration)
 * @param {*} value1 
 * @param {*} value2 
 * @returns {bool}
 */
const checkIfEqual = (value1, value2) => {
	let isEqual = true;

	const value1IsArray = checkIfObjectIsArray(value1);
	const value2IsArray = checkIfObjectIsArray(value2);

	if (value1IsArray && value2IsArray) {
		// compare arrays
		if (value1.length === value2.length) {
			// Arrays are equal in length
			isEqual = value1.every((item) => {
				return value2.includes(item);
			}) && value2.every((item) => {
				return value1.includes(item);
			});
		} else {
			// Arrays are not equal in length
			isEqual = false;
		}
	} else if ((value1IsArray && !value2IsArray) || (!value1IsArray && value2IsArray)) {
		// Only one is array, not equals
		isEqual = false;
	} else {
		// No arrays, simply check values
		isEqual = value1 === value2;
	}

	return isEqual;
};

/**
 * Generate random id of specific length
 * @param {string} length 
 * @returns 
 */
const generateRandomId = (length) => {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	let counter = 0;
	while (counter < length) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
		counter += 1;
	}
	return result;
};

/**
 * Makes a deep copy of the given object
 * @param {*} obj 
 * @returns 
 */
const getDeepCopyObject = (obj) => {
	return JSON.parse(JSON.stringify(obj));
};


export {
	checkIfObjectIsArray,
	checkIfObjectIsEmpty,
	checkIfEqual,
	generateRandomId,
	getDeepCopyObject
};