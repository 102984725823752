/* eslint-disable quote-props */
const manifestData = {
	'short_name': 'Platform',
	name: 'Platform',
	'start_url': '.',
	display: 'standalone',
	'theme_color': '#000000',
	'background_color': '#ffffff'
};

export {
	manifestData
};
