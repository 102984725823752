import appConfig from 'config/app.config';
import {languagesData} from 'data/languages-data';

/**
 * Get browser language
 * @returns {string}
 */
const getBrowserLanguage = () => {
	let browserLanguage = window.navigator.userLanguage || window.navigator.language;
	if (browserLanguage) browserLanguage = browserLanguage.split('-')[0];

	if (!browserLanguage || !languagesData.some((lng) => {return lng.id === browserLanguage.toString();})) {
		browserLanguage = appConfig.defaultLanguage;
	}

	return browserLanguage;
};

/**
 * Get data for available languages
 * @param {string} type 
 * @param {object} companyData 
 * @param {boolean} useShortTitle
 */
const getAvailableLanguagesData = (type, companyData, courseData, useShortTitle = false) => {
	/* Get available language ids */
	let languageIds = ['da', 'en'];
	if (type === 'cpanel') {
		languageIds = ['da', 'en'];
	} else if (type === 'company' && companyData && companyData.languageIds && companyData.languageIds.length > 0) {
		const companyLanguageIds = [];
		companyData.languageIds.forEach((languageId) => {
			/* Only include languages we have data for */
			if (languagesData.some((languageData) => {return languageData.id === languageId;})) {
				companyLanguageIds.push(languageId);
			}
		});
		if (companyLanguageIds.length > 0) {
			languageIds = companyLanguageIds;
		}
	} else if (type === 'course' && courseData && courseData.languageIds && courseData.languageIds.length > 0) {
		const courseLanguageIds = [];
		courseData.languageIds.forEach((languageId) => {
			/* Only include languages we have data for */
			if (languagesData.some((languageData) => {return languageData.id === languageId;})) {
				courseLanguageIds.push(languageId);
			}
		});
		if (courseLanguageIds.length > 0) {
			languageIds = courseLanguageIds;
		}
	}

	/* Get data for available language ids */
	let availableLanguagesData = languagesData.filter((l) => {return languageIds.includes(l.id);});

	/* Use short title of language */
	if (useShortTitle) {
		availableLanguagesData = availableLanguagesData.map((data) => {
			return {id: data.id, localization: data.localization, shortTitle: data.shortTitle, title: data.title};
		});
	}

	return availableLanguagesData;
};

export {
	getBrowserLanguage,
	getAvailableLanguagesData,
};