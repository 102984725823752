import React from 'react';
import PropTypes from 'prop-types';
import './button.scss';
import './button-cpanel.scss';
import './button-admin.scss';
import './button-facilitator.scss';
import './button-style-1.scss';

const Button = ({isLoading = false, isDisabled = false, text, classes, type, href, onClick}) => {

	/**
	 * Handle button click
	 * @param {object} event 
	 */
	const handleClick = (event) => {
		if (isDisabled) event.preventDefault();
		else if (onClick) {
			event.persist();
			event.stopPropagation();
			onClick(event);
		}
	};

	/* Add class if loading / disabled */
	let className = 'Button';
	if (isLoading) {className += ' loading';}
	if (isDisabled) {className += ' disabled';}

	/* Add custom classes */
	if (classes && classes.length > 0) {
		classes.forEach((c) => {className = className + ' ' + c;});
	}
		
	/* Type: link */
	if (type === 'link' && href) {
		return (
			<a className={className} href={href} target="_blank" rel="noreferrer">{text && text}</a>
		);
	}

	/* Type: download */
	if (type === 'download' && href) {
		return (
			<a className={className} download href={href}>
				{text && <span>{text}</span>}
			</a>
		);	
	}
	
	if (onClick) {
		/* Type: submit */
		if (type === 'submit') {
			return ( 
				<button type={type} className={className + ' ' + type} onClick={handleClick}>
					{text && <span>{text}</span>}
				</button>
			);
		}

		/* Type: default */
		return <div className={className} onClick={handleClick}><span>{text}</span></div>;
	}

	return null;	
};



Button.propTypes = {
	isLoading: PropTypes.bool,
	isDisabled: PropTypes.bool,
	text: PropTypes.string,
	classes: PropTypes.array,
	type: PropTypes.string,
	href: PropTypes.string,
	onClick: PropTypes.func
};

export default Button;