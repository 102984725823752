const moduleGroupUiTexts = {
	name: {
		da: 'Navn',
		en: 'Name',
		se: `Namn`,
		de: `Name`,
		pl: `Nazwa`,
		ro: `Nume`,
		ua: `Ім'я`,
	},
	requiredModuleGroups: {
		da: 'Krævede modulgrupper',
		en: 'Required module groups',
		se: `Obligatoriska modulgrupper`,
		de: `Erforderliche Modulgruppen`,
		pl: `Wymagane grupy modułów`,
		ro: `Grupuri de module necesare`,
		ua: `Обов'язкові групи модулів`,
	},
	waitingPeriod: {
		da: 'Karensperiode (mdr)',
		en: 'Waiting period (mos)',
		se: `Självrisktid (månader)`,
		de: `Wartezeit (Monate)`,
		pl: `Okres karencji (miesiące)`,
		ro: `Perioada de așteptare (luni)`,
		ua: `Термін очікування (місяці)`,
	},
	expiresAfterXMonths: {
		da: 'Udløber efter X måneder',
		en: 'Expires after X months',
		se: `Förfaller efter X månader`,
		de: `Läuft nach X Monaten ab`,
		pl: `Wygasa po X miesiącach`,
		ro: `Expiră după X luni`,
		ua: `Термін дії закінчується через X місяців`,
	},
	nextModuleGroup: {
		da: 'Næste modulgruppe',
		en: 'Next module group',
		se: `Nästa modulgrupp`,
		de: `Nächste Modulgruppe`,
		pl: `Następna grupa modułów`,
		ro: `Următorul grup de module`,
		ua: `Наступна група модулів`,
	},
	availableAfterXMonths: {
		da: 'Tilgængelig efter X måneder',
		en: 'Available after X months',
		se: `Tillgänglig efter X månader`,
		de: `Verfügbar nach X Monaten`,
		pl: `Dostępne po X miesiącach`,
		ro: `Disponibil după X luni`,
		ua: `Доступно після X місяців`,
	}
};

export {
	moduleGroupUiTexts
};