const uiTexts = {
	admin: require('./ui-texts/admin-ui-texts').adminUiTexts,
	button: require('./ui-texts/button-ui-texts').buttonUiTexts,
	company: require('./ui-texts/company-ui-texts').companyUiTexts,
	course: require('./ui-texts/course-ui-texts').courseUiTexts,
	cPanel: require('./ui-texts/cpanel-ui-texts.js').cpanelUiTexts,
	error: require('./ui-texts/error-ui-texts').errorUiTexts,
	facilitator: require('./ui-texts/facilitator-ui-texts.js').facilitatorUiTexts,
	general: require('./ui-texts/general-ui-texts').generalUiTexts,
	login: require('./ui-texts/login-ui-texts.js').loginUiTexts,
	player: require('./ui-texts/player-ui-texts.js').playerUiTexts,
	user: require('./ui-texts/user-ui-texts').userUiTexts,
	stats: require('./ui-texts/stats-ui-texts').statsUiTexts,
	game: require('./ui-texts/game-ui-texts').gameUiTexts,
	session: require('./ui-texts/session-ui-texts').sessionUiTexts,

	gameType1: require('./ui-texts/type-1/game-ui-texts').gameUiTexts,
	module: require('./ui-texts/type-1/module-ui-texts').moduleUiTexts,
	moduleGroup: require('./ui-texts/type-1/module-group-ui-texts').moduleGroupUiTexts,
	task: require('./ui-texts/type-1/task-ui-texts').taskUiTexts,
};


export {
	uiTexts
};