import { redirectData } from 'data/redirect-data';

/**
 * Checks for whether redirection should take place, and performs it if necessary 
 * @param {string} currentUrl 
 */
const handleRedirect = (currentUrl) => {
	const redirectMatch = redirectData.find((data) => {
		return currentUrl.includes(data.fromUrl);
	});

	// Match found, we redirect to resulting new url
	if (redirectMatch) {
		window.location.replace(redirectMatch.toUrl);
	}
};




export {
	handleRedirect
};