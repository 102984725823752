import { faro, LogLevel } from '@grafana/faro-react';
import appConfig from 'config/app.config';

/**
 * Acceptable log levels for logging sessions.
 */
const levels = {
	TRACE: LogLevel.TRACE,
	DEBUG: LogLevel.DEBUG,
	INFO: LogLevel.INFO,
	LOG: LogLevel.LOG,
	WARN: LogLevel.WARN,
	ERROR: LogLevel.ERROR,
};

/**
 * Logs message to online loggin session. Does not log on demo server.
 * @param {string} level 
 * @param {string} message
 */
const logMessage = (level, message) => {
	if (appConfig.env !== 'demo') {
		faro.api.pushLog([message], {
			level: level
		});
	}
};

/**
 * Pauses log session
 */
const pauseLogSession = () => {
	if (appConfig.env !== 'demo') {
		faro.pause();
	}
};

/**
 * Unpauses log session
 */
const unpauseLogSession = () => {
	if (appConfig.env !== 'demo') {
		faro.unpause();
	}
};

export {
	levels,
	logMessage,
	pauseLogSession,
	unpauseLogSession
};