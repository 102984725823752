const userUiTexts = {
	name: {
		da: `Navn`,
		en: `Name`,
		se: `Namn`,
		de: `Name`,
		pl: `Nazwa`,
		ro: `Nume`,
		ua: `Ім'я`,
	},
	email: {
		da: 'E-mail',
		en: 'E-mail',
		se: 'E-post',
		de: 'E-mail',
		pl: 'E-mail',
		ro: 'E-mail',
		ua: 'Електронна пошта',
	},
	team: {
		da: 'Team',
		en: 'Team',
		se: 'Team',
		de: 'Team',
		pl: 'Zespół',
		ro: 'Echipă',
		ua: 'Команда',
	},
	position: {
		da: 'Stilling',
		en: 'Position',
		se: 'Position',
		de: 'Position',
		pl: 'Stanowisko',
		ro: 'Poziţie',
		ua: 'Позиція'
	},
	created: {
		da: 'Oprettet',
		en: 'Created',
		se: 'Skapad',
		de: 'Erstellt',
		pl: 'Utworzony',
		ro: 'Creat',
		ua: 'Створено',
	},
	lastLogin: {
		da: 'Sidste login',
		en: 'Last login',
		se: 'Senaste inloggning',
		de: 'Letzte Anmeldung',
		pl: 'Ostatnie logowanie',
		ro: 'Ultima logare',
		ua: 'Останній вхід',
	},
	role: {
		da: 'Rolle',
		en: 'Role',
		se: 'Roll',
		de: 'Rolle',
		pl: 'Rola',
		ro: 'Rol',
		ua: 'Роль',
	},
	player: {
		da: 'Spiller',
		en: 'Player',
		se: 'Spelare',
		de: 'Spieler',
		pl: 'Gracz',
		ro: 'Jucător',
		ua: 'гравець',
	},
	facilitator: {
		da: 'Facilitator',
		en: 'Facilitator',
		se: 'Kontaktperson',
		de: 'Moderator',
		pl: 'Moderator',
		ro: 'Facilitator',
		ua: 'Фасилітатор',
	},
	admin: {
		da: 'Admin',
		en: 'Admin',
		se: 'Admin',
		de: 'Admin',
		pl: 'Admin',
		ro: 'Admin',
		ua: 'адмін',
	},

	/* Player specific ui texts */
	status: {
		da: 'Status',
		en: 'Status',
		se: 'Status',
		de: 'Status',
		pl: 'Status',
		ro: 'Stare',
		ua: 'Статус',
	},
	active: {
		da: 'Aktiv',
		en: 'Active',
		se: 'Aktiv',
		de: 'Aktiv',
		pl: 'Aktywny',
		ro: 'Activ',
		ua: 'Активний',
	},
	inactive: {
		da: 'Inaktiv',
		en: 'Inactive',
		se: 'Inaktiv',
		de: 'Inaktiv',
		pl: 'Nieaktywny',
		ro: 'Inactiv',
		ua: 'неактивний',
	},

	/* ERRORS */
	noUserData: {
		da: 'Fejl: Brugerdata ikke fundet.',
		en: 'Error: user data not found.',
		se: 'Fel: Användardata hittades inte.',
		de: 'Fehler: Benutzerdaten nicht gefunden.',
		pl: 'Błąd: Nie znaleziono danych użytkownika.',
		ro: 'Eroare: datele utilizatorului nu au fost găsite.',
		ua: 'Помилка: дані користувача не знайдені.',
	}
};

export {
	userUiTexts
};