/**
 * Gets the orientation of the screen 
 * @returns {string} orientation
 */
const getOrientation = () => {
	const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
	const orientation = (width > height ? 'landscape' : 'portrait');
	return orientation;
};

/**
 * Get the view of the window
 * @returns {string} view
 */
const getView = () => {
	const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
	const view = getCustomView(width, height);
	return view;
};

/**
 * Get custom view
 * @param {number} width 
 * @param {number} height 
 * @returns 
 */
const getCustomView = (width, height) => {
	let view = 'landscape';
	if (width > height) {
		if (width / height <= 4. / 3.) {
			view = 'landscape-narrow';
		}
	} else {
		if (width / height >= 2. / 3.) {
			view = 'portrait-wide';
		} else {
			view = 'portrait';
		}
	}
	return view;
};

/**
 * Gets the aspect ratio of the screen
 * @returns {number} aspectRatio
 */
const getAspectRatio = () => {
	const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
	const aspectRatio = parseFloat((width / height).toFixed(2));
	return aspectRatio;
};

/**
 * Check if the app is running in standalone mode
 * @returns {bool} isInStandaloneMode
 */
const checkIfInStandaloneMode = () => {
	const isInStandaloneMode = 
		window.navigator.standalone === true || window.matchMedia('(display-mode: standalone)').matches;
	return isInStandaloneMode;
};


export {
	getOrientation,
	getView,
	getCustomView,
	getAspectRatio,
	checkIfInStandaloneMode
};