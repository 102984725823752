const loginUiTexts = {
	login: {
		da: 'Log ind',
		en: 'Log in',
		se: 'Logga in',
		de: 'Anmeldung',
		pl: 'Zaloguj się',
		ro: 'Conectare',
		ua: 'авторизуватися',
	},
	loginWithMicrosoftSSO: {
		da: 'Log ind med Microsoft SSO',
		en: 'Log in with Microsoft SSO',
		se: 'Logga in med Microsoft SSO',
		de: 'Mit Microsoft SSO anmelden',
		pl: 'Zaloguj się za pomocą usługi Microsoft SSO',
		ro: 'Conectați-vă cu Microsoft SSO',
		ua: 'Увійдіть за допомогою Microsoft SSO',
	},
	logout: {
		da: 'Log ud',
		en: 'Log out',
		se: 'Logga ut',
		de: 'Ausloggen',
		pl: 'Wyloguj się',
		ro: 'Deconectare',
		ua: 'вийти',
	},
	loginInAsFacilitator: {
		da: 'Log ind som facilitator',
		en: 'Log in as facilitator',
		se: 'Logga in som facilitator',
		de: 'Als Moderator anmelden',
		pl: 'Zaloguj się jako moderator',
		ro: 'Conectați-vă ca facilitator',
		ua: 'Увійдіть як фасилітатор',
	},
	loginAsPlayer: {
		da: 'Log ind som spiller',
		en: 'Log in as player',
		se: 'Logga in som spelare',
		de: 'Als Spieler anmelden',
		pl: 'Zaloguj się jako gracz',
		ro: 'Conectați-vă ca jucător',
		ua: 'Увійдіть як гравець',
	},
	facilitator: {
		da: 'Facilitator',
		en: 'Facilitator',
		se: 'Kontaktperson',
		de: 'Moderator',
		pl: 'Moderator',
		ro: 'Facilitator',
		ua: 'Фасилітатор',
	},
	player: {
		da: 'Spiller',
		en: 'Player',
		se: 'Spelare',
		de: 'Spieler',
		pl: 'Gracz',
		ro: 'Jucător',
		ua: 'гравець',
	},
	loading: {
		da: 'Loader',
		en: 'Loading',
		se: 'Laddar',
		de: 'Wird geladen',
		pl: 'Ładowanie',
		ro: 'Se încarcă',
		ua: 'Навантажувач',
	},
	confirmPlayerId: {
		da: 'Er dette dit %playerIdLabel%?',
		en: 'Is this your %playerIdLabel%?',
		se: 'Är detta din %playerIdLabel%?',
		de: 'Ist dies Ihr %playerIdLabel%?',
		pl: 'Czy to jest Twój %playerIdLabel%?',
		ro: 'Acesta este %playerIdLabel% al tău?',
		ua: 'Це ваш %playerIdLabel%?',
	},
	loginTextExternal: {
		da: `Hvis du er ekstern så log ind med din e-mail.`,
		en: `If you are external, log in using your e-mail.`,
		se: `Om du är extern, logga in med din e-post.`,
		de: `Wenn Sie extern sind, melden Sie sich mit Ihrer E-Mail-Adresse an.`,
		pl: `Jeśli jesteś spoza firmy, zaloguj się przy użyciu swojego adresu e-mail.`,
		ro: `Dacă sunteți extern, conectați-vă folosind adresa de e-mail.`,
		ua: `Якщо ви зовнішній, увійдіть за допомогою електронної пошти.`
	},

	/* Input names */
	email: {
		da: 'E-mail',
		en: 'E-mail',
		se: 'e-post',
		de: 'E-mail',
		pl: 'E-mail',
		ro: 'E-mail',
		ua: 'Електронна пошта',
	},
	password: {
		da: 'Password',
		en: 'Password',
		se: 'lösenord',
		de: 'Passwort',
		pl: 'Hasło',
		ro: 'Parola',
		ua: 'пароль',
	},
	repeatPassword: {
		da: 'Gentag password',
		en: 'Repeat password',
		se: 'upprepa lösenord',
		de: 'Passwort wiederholen',
		pl: 'Powtórz hasło',
		ro: 'Repetați parola',
		ua: 'повторити пароль',
	},
	playerId: {
		da: 'Spiller-id',
		en: 'Player id',
		se: 'Spelar-id',
		de: 'Spieler-ID',
		pl: 'Identyfikator gracza',
		ro: 'ID-ul jucătorului',
		ua: 'ID гравця',
	},

	/* Login info */
	loggedInAs: {
		da: 'Logget ind som',
		en: 'Logged in as',
		se: 'Inloggad som',
		de: 'Angemeldet als',
		pl: 'Zalogowany jako',
		ro: 'Conectat ca',
		ua: 'Ви увійшли як',
	},

	/* Create user / reset password */
	backToLogin: {
		da: 'Tilbage til login',
		en: 'Back to login',
		se: 'Tillbaka till inloggning',
		de: 'Zurück zur Anmeldung',
		pl: 'Powrót do logowania',
		ro: 'Înapoi la autentificare',
		ua: 'Назад до входу',
	},
	createUser: {
		da: 'Opret bruger',
		en: 'Create user',
		se: 'Skapa användare',
		de: 'Benutzer erstellen',
		pl: 'Stwórz użytkownika',
		ro: 'Creaza utilizator',
		ua: 'Створити користувача',
	},
	userAlreadyExists: {
		da: 'Der eksisterer allerede en bruger med denne e-mail.',
		en: 'This e-mail is already in use.',
		se: 'Denna e-post är redan i bruk.',
		de: 'Diese E-Mail-Adresse wird schon verwendet.',
		pl: 'Ten email jest już w użyciu.',
		ro: 'Acest email este deja folosit.',
		ua: 'Ця електронна адреса вже використовується.',
	},
	invalidPassword: {
		da: 'Ugyldigt password. Dit password skal være på mindst 6 tegn.',
		en: 'Invalid password. Your password must contain at least 6 characters.',
		se: 'Ogiltigt lösenord. Ditt lösenord måste vara minst 6 tecken långt.',
		de: 'Ungültiges Passwort. Ihr Passwort muss mindestens 6 Zeichen lang sein.',
		pl: 'Nieprawidłowe hasło. Twoje hasło musi mieć co najmniej 6 znaków.',
		ro: 'Parolă nevalidă. Parola dvs. trebuie să aibă cel puțin 6 caractere.',
		ua: 'Недійсний пароль. Ваш пароль має містити щонайменше 6 символів.'
	},
	userNotAllowed: {
		da: 'Du kan ikke oprette en bruger med denne e-mail.',
		en: 'You cannot create a user with this e-mail.',
		se: 'Du kan inte skapa en användare med denna e-postadress.',
		de: 'Sie können mit dieser E-Mail keinen Benutzer erstellen.',
		pl: 'Nie możesz utworzyć użytkownika przy użyciu tego adresu e-mail.',
		ro: 'Nu puteți crea un utilizator cu acest e-mail.',
		ua: 'Ви не можете створити користувача з цією електронною адресою.',
	},
	userCreationTitle: {
		da: 'Din bruger er oprettet.',
		en: 'Your user is created.',
		se: 'Din användare är skapad.',
		de: 'Ihr Benutzer wurde erstellt.',
		pl: 'Twój użytkownik został utworzony.',
		ro: 'Utilizatorul dvs. este creat.',
		ua: 'Ваш користувач створений.',
	},
	userCreationMessage: {
		da: `En verificeringsmail er blevet sendt til din emailkonto.<br />Venligst verificer din bruger for at få adgang til siden.`,
		en: `A verification email has been sent to your email account.<br />Please verify your user to gain access to the site.`,
		se: 'Ett verifieringsmeddelande har skickats till ditt e-postkonto.<br />Vänligen verifiera din användare för att få tillgång till webbplatsen.',
		de: 'Eine Bestätigungs-E-Mail wurde an Ihr E-Mail-Konto gesendet.<br />Bitte bestätigen Sie Ihren Benutzer, um Zugriff auf die Site zu erhalten.',
		pl: 'Wiadomość e-mail z potwierdzeniem została wysłana na Twój adres e-mail.<br />Aby uzyskać dostęp do witryny, zweryfikuj swoje dane użytkownika.',
		ro: 'A fost trimis un e-mail de verificare către contul dvs. de e-mail.<br />Vă rugăm să verificați utilizatorul pentru a obține acces la site.',
		ua: 'На ваш обліковий запис електронної пошти надіслано електронний лист для підтвердження.<br />Будь ласка, підтвердьте свого користувача, щоб отримати доступ до сайту.',
	},
	userCreationMessage2: {
		da: `Log ind for at verificiere din bruger.`,
		en: `Login to verify your user.`,
		se: 'Logga in för att verifiera din användare.',
		de: 'Melden Sie sich an, um Ihren Benutzer zu verifizieren.',
		pl: 'Zaloguj się, aby zweryfikować swojego użytkownika.',
		ro: 'Conectați-vă pentru a vă verifica utilizatorul.',
		ua: 'Увійдіть, щоб підтвердити свого користувача.',
	},
	resetPassword: {
		da: 'Nulstil password',
		en: 'Reset password',
		se: 'Återställ lösenord',
		de: 'Passwort zurücksetzen',
		pl: 'Zresetuj hasło',
		ro: 'Resetați parola',
		ua: 'Скинути пароль',
	},
	resetPasswordMessage: {
		da: 'Indtast din e-mail for at nulstille dit password.',
		en: 'Enter your e-mail to reset your password.',
		se: 'Ange din e-postadress för att återställa ditt lösenord.',
		de: 'Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen.',
		pl: 'Podaj swój adres e-mail, aby zresetować hasło.',
		ro: 'Introduceți adresa de e-mail pentru a vă reseta parola.',
		ua: 'Введіть адресу електронної пошти, щоб змінити пароль.',
	},
	resetPasswordConfirmation: {
		da: 'Check din indbakke - vi har sendt dig en e-mail med et link til at nulstille dit password.<br /><br />Dukkede den ikke op? Tjek din spam-mappe!',
		en: 'Check you inbox - we have send you an e-mail with a link to reset your password.<br/><br />No e-mail? Check your spam folder!',
		se: 'Kolla din inkorg - vi har skickat ett e-postmeddelande med en länk för att återställa ditt lösenord.<br/><br />Ingen e-post? Kolla i din skräppost!',
		de: 'Überprüfen Sie Ihren Posteingang. Wir haben Ihnen eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts gesendet.<br/><br />Keine E-Mail? Überprüfen Sie Ihren Spam-Ordner!',
		pl: 'Sprawdź skrzynkę odbiorczą - wysłaliśmy Ci e-mail z linkiem do zresetowania hasła.<br/><br />Brak e-maila? Sprawdź folder ze spamem!',
		ro: 'Verificați-vă căsuța de e-mail - v-am trimis un e-mail cu un link pentru a vă reseta parola.<br/><br />Niciun e-mail? Verificați folderul de spam!',
		ua: 'Перевірте свою поштову скриньку - ми надіслали вам електронний лист із посиланням для скидання пароля.<br/><br />Нема електронної пошти? Перевірте папку зі спамом!',
	},
	resetPasswordFor: {
		da: 'Nulstil password for %email%.',
		en: 'Reset password for %email%.',
		se: 'Återställ lösenordet för %email%.',
		de: 'Passwort für %email% zurücksetzen.',
		pl: 'Zresetuj hasło dla %email%.',
		ro: 'Resetați parola pentru %email%.',
		ua: 'Скинути пароль для %email%.',
	},
	resetLinkExpiredOrUsed: {
		da: 'Linket er enten udløbet eller allerede blevet brugt til at nulstille dit password. Gå tilbage til log ind og prøv igen.',
		en: 'The link is either expired or has already been used to reset your password. Go back to login and try again.',
		se: 'Länken har antingen gått ut eller har redan använts för att återställa ditt lösenord. Gå tillbaka för att logga in och försök igen.',
		de: 'Der Link ist entweder abgelaufen oder wurde bereits zum Zurücksetzen Ihres Passworts verwendet. Kehren Sie zur Anmeldung zurück und versuchen Sie es erneut.',
		pl: 'Link wygasł lub został już użyty do zresetowania hasła. Wróć do logowania i spróbuj ponownie.',
		ro: 'Linkul fie a expirat, fie a fost deja folosit pentru a vă reseta parola. Reveniți la autentificare și încercați din nou.',
		ua: 'Термін дії посилання закінчився, або воно вже використовувалося для скидання пароля. Поверніться до входу та повторіть спробу.',
	},
	userDisabledOrDoesNotExist: {
		da: 'Brugeren er enten suspenderet eller eksisterer ikke.',
		en: 'The user is either disabled or does not exist.',
		se: 'Användaren är antingen inaktiverad eller existerar inte.',
		de: 'Der Benutzer ist entweder deaktiviert oder existiert nicht.',
		pl: 'Użytkownik jest wyłączony lub nie istnieje.',
		ro: 'Utilizatorul fie este dezactivat, fie nu există.',
		ua: 'Користувач або вимкнено, або не існує.',
	},
	resetPasswordSuccess: {
		da: 'Dit password er blevet nulstillet!',
		en: 'Your password has been reset!',
		se: 'Ditt lösenord har blivit återställt!',
		de: 'Dein Passwort wurde zurück gesetzt!',
		pl: 'Twoje hasło zostało zresetowane!',
		ro: 'Parola ta a fost resetata!',
		ua: 'Ваш пароль скинуто!',
	},

	/* Errors */
	emptyFields: {
		da: 'Husk at udfylde alle felter.',
		en: 'Please fill out all fields.',
		se: 'Vänligen fyll i alla fält.',
		de: 'Bitte fülle alle Felder aus.',
		pl: 'Proszę wypełnić wszystkie pola.',
		ro: 'Vă rugăm să completați toate câmpurile.',
		ua: 'Будь ласка, заповніть усі поля.',
	},
	yourPlayerIdMustBeValidEmail: {
		da: 'Dit %playerId% skal være en gyldigt e-mail.',
		en: 'Your %playerId% must be a valid e-mail.',
		se: 'Ditt %playerId% måste vara en giltig e-postadress.',
		de: 'Ihre %playerId% muss eine gültige E-Mail sein.',
		pl: 'Twój %playerId% musi być prawidłowym adresem e-mail.',
		ro: '%playerId% trebuie să fie un e-mail valid.',
		ua: 'Ваш %playerId% має бути дійсною електронною поштою.',
	},
	playerIdMustBeValidEmail: {
		da: '%playerId% skal være en gyldigt e-mail.',
		en: '%playerId% must be a valid e-mail.',
		se: '%playerId% måste vara en giltig e-postadress.',
		de: '%playerId% muss eine gültige E-Mail-Adresse sein.',
		pl: '%playerId% musi być prawidłowym adresem e-mail.',
		ro: '%playerId% trebuie să fie un e-mail valid.',
		ua: '%playerId% має бути дійсною електронною адресою.',
	},
	yourPlayerIdMustBeExactLength: {
		da: 'Dit %playerId% skal være på præcis %length% tegn.',
		en: 'Your %playerId% must be exactly %length% characters long.',
		se: 'Ditt %playerId% måste vara exakt %length% tecken långt.',
		de: 'Ihre %playerId% muss genau %length% Zeichen lang sein.',
		pl: 'Twój %playerId% musi mieć dokładnie %length% znaków.',
		ro: '%playerId% trebuie să aibă exact %length% caractere.',
		ua: 'Ваш %playerId% має містити рівно %length% символів.',
	},
	playerIdMustBeExactLength: {
		da: '%playerId% skal være på præcis %length% tegn.',
		en: '%playerId% must be exactly %length% characters long.',
		se: '%playerId% måste vara exakt %length% tecken långt.',
		de: 'Die %playerId% muss genau %length% Zeichen lang sein.',
		pl: '%playerId% musi mieć dokładnie %length% znaków.',
		ro: '%playerId% musi mieć dokładnie %length% znaków.',
		ua: '%playerId% має додатковий %length% знак.',
	},
	yourPlayerIdMustBeBetween: {
		da: 'Dit %playerId% skal være på %min%-%max% tegn.',
		en: 'Your %playerId% must be %min%-%max% characters long.',
		se: 'Ditt %playerId% måste vara %min%-%max% tecken långt.',
		de: 'Ihre %playerId% muss %min%-%max% Zeichen lang sein.',
		pl: 'Twój %playerId% musi mieć %min%-%max% znaków.',
		ro: '%playerId% trebuie să aibă %min%-%max% caractere.',
		ua: 'Ваш %playerId% має містити %min%-%max% символів.',
	},
	playerIdMustBeBetween: {
		da: '%playerId% skal være på %min%-%max% tegn.',
		en: '%playerId% must be %min%-%max% characters long.',
		se: '%playerId% måste vara %min%-%max% tecken långa.',
		de: '%playerId% muss %min%-%max% Zeichen lang sein.',
		pl: '%playerId% musi mieć %min%-%max% znaków.',
		ro: '%playerId% trebuie să aibă %min%-%max% caractere.',
		ua: '%playerId% має містити %min%-%max% символів.',
	},
	yourPlayerIdNumbersOnly: {
		da: 'Dit %playerId% må kun bestå af tal.',
		en: 'Your %playerId% can only contain numbers.',
		se: 'Ditt %playerId% kan bara innehålla siffror.',
		de: 'Ihre %playerId% darf nur Zahlen enthalten.',
		pl: 'Twój %playerId% może zawierać tylko liczby.',
		ro: '%playerId% poate conține numai numere.',
		ua: 'Ваш %playerId% може містити лише цифри.',
	},
	playerIdNumbersOnly: {
		da: '%playerId% må kun bestå af tal.',
		en: '%playerId% can only contain numbers.',
		se: '%playerId% kan bara innehålla siffror.',
		de: '%playerId% darf nur Zahlen enthalten.',
		pl: '%playerId% może zawierać tylko liczby.',
		ro: '%playerId% poate conține doar numere.',
		ua: '%playerId% може містити лише числа.',
	},
	yourPlayerIdLettersAndNumbersOnly: {
		da: 'Dit %playerId% må kun bestå af tal og bogstaver (undtagen æ, ø, å).',
		en: 'Your %playerId% may only contain numbers and letters.',
		se: 'Ditt %playerId% får bara innehålla siffror och bokstäver.',
		de: 'Ihre %playerId% darf nur Zahlen und Buchstaben enthalten.',
		pl: 'Twój %playerId% może zawierać tylko cyfry i litery.',
		ro: '%playerId% dvs. poate conține numai cifre și litere.',
		ua: 'Ваш %playerId% може містити лише цифри та літери.',
	},
	playerIdLettersAndNumbersOnly: {
		da: '%playerId% må kun bestå af tal og bogstaver (undtagen æ, ø, å).',
		en: '%playerId% may only contain numbers and letters.',
		se: '%playerId% får bara innehålla siffror och bokstäver.',
		de: '%playerId% darf nur Zahlen und Buchstaben enthalten.',
		pl: '%playerId% może zawierać tylko cyfry i litery.',
		ro: '%playerId% poate conține doar cifre și litere.',
		ua: '%playerId% може містити лише цифри та літери.',
	},
	yourPlayerIdInvalidRegex: {
		da: 'Dit %playerId% har et ugyldigt format.',
		en: 'Your %playerId% has an invalid format.',
		se: 'Ditt %playerId% har ett ogiltigt format.',
		de: 'Ihre %playerId% hat ein ungültiges Format.',
		pl: 'Twój %playerId% ma nieprawidłowy format.',
		ro: '%playerId% dvs. are un format nevalid.',
		ua: 'Ваш %playerId% має недійсний формат.',
	},
	playerIdInvalidRegex: {
		da: '%playerId% har et ugyldigt format.',
		en: '%playerId% has an invalid format.',
		se: '%playerId% har ett ogiltigt format.',
		de: '%playerId% hat ein ungültiges Format.',
		pl: '%playerId% ma nieprawidłowy format.',
		ro: '%playerId% are un format nevalid.',
		ua: '%playerId% має недійсний формат.',
	},
	invalidEmail: {
		da: 'Ugyldig e-mail.',
		en: 'Invalid e-mail.',
		se: 'Ogiltig e-postadress.',
		de: 'Ungültige E-Mail.',
		pl: 'Niepoprawny email.',
		ro: 'E-mail invalid.',
		ua: 'Недійсна електронна адреса.',
	},
	passwordsDoNotMatch: {
		da: 'Passwords er forskellige.',
		en: 'Passwords do not match.',
		se: 'Lösenorden matchar inte.',
		de: 'Passwörter stimmen nicht überein.',
		pl: 'Hasła nie pasują do siebie.',
		ro: 'Parolele nu se potrivesc.',
		ua: 'Паролі не збігаються.',
	},
	wrongEmailLogin: {
		da: 'Fejl. Check om din e-mail og password er korrekte.',
		en: 'Error. Check you e-mail and password.',
		se: 'Fel. Kontrollera om din e-postadress och ditt lösenord är korrekta.',
		de: 'Fehler. Überprüfen Sie, ob Ihre E-Mail-Adresse und Ihr Passwort korrekt sind.',
		pl: 'Błąd. Sprawdź, czy Twój adres e-mail i hasło są poprawne.',
		ro: 'Eroare. Verificați dacă adresa dvs. de e-mail și parola sunt corecte.',
		ua: 'Помилка. Перевірте, чи правильна ваша адреса електронної пошти та пароль.',
	},
	unknownEmail: {
		da: 'Fejl. Check om din e-mail er korrekt.',
		en: 'Error. Check if your e-mail is correct.',
		se: 'Fel. Kontrollera om din e-postadress är korrekt.',
		de: 'Fehler. Überprüfen Sie, ob Ihre E-Mail korrekt ist.',
		pl: 'Błąd. Sprawdź, czy Twój adres e-mail jest poprawny.',
		ro: 'Eroare. Verificați dacă e-mailul dvs. este corect.',
		ua: 'Помилка. Перевірте, чи правильна ваша електронна пошта.',
	},
	onlyInvitedPlayersCanLogin: {
		da: 'Kun inviterede / importerede spillere kan logge ind.',
		en: 'Only invited / imported players can log in.',
		se: 'Endast inbjudna/importerade spelare kan logga in.',
		de: 'Nur eingeladene/importierte Spieler können sich anmelden.',
		pl: 'Mogą się zalogować tylko zaproszeni/zaimportowani gracze.',
		ro: 'Numai jucătorii invitați/importați se pot autentifica.',
		ua: 'Тільки запрошені/імпортовані гравці можуть увійти.'
	},
	onlyInvitedFacilitatorsCanLogin: {
		da: 'Kun inviterede facilitatorer / admins kan logge ind.',
		en: 'Only invited facilitators / admins can log in.',
		se: 'Endast inbjudna handledare/admins kan logga in.',
		de: 'Nur eingeladene Moderatoren/Administratoren können sich anmelden.',
		pl: 'Mogą się zalogować tylko zaproszeni moderatorzy/administratorzy.',
		ro: 'Numai facilitatorii/administratorii invitați se pot autentifica.',
		ua: 'Тільки запрошені фасилітатори / адміністратори можуть увійти.'
	},
	onlyInvitedUsersCanLogin: {
		da: 'Kun inviterede brugere kan logge ind.',
		en: 'Only invited users can log in.',
		se: 'Endast inbjudna användare kan logga in.',
		de: 'Nur eingeladene Benutzer können sich anmelden.',
		pl: 'Tylko zaproszeni użytkownicy mogą się zalogować.',
		ro: 'Numai utilizatorii invitați se pot autentifica.',
		ua: 'Тільки запрошені користувачі можуть увійти.'
	},
	unknownLoginError: {
		da: 'Fejl. Der skete en ukendt fejl på serveren.',
		en: 'Error. The server returned an unexpected error.',
		se: 'Fel. Servern returnerade ett oväntat fel.',
		de: 'Fehler. Der Server hat einen unerwarteten Fehler zurückgegeben.',
		pl: 'Błąd. Serwer zwrócił nieoczekiwany błąd.',
		ro: 'Eroare. Serverul a returnat o eroare neașteptată.',
		ua: 'Помилка. Сервер повернув неочікувану помилку.',
	},

	unverifiedTitle: {
		da: 'Uverificeret konto',
		en: 'Unverified account',
		se: 'Overifierat konto',
		de: 'Nicht verifiziertes Konto',
		pl: 'Niezweryfikowane konto',
		ro: 'Cont neverificat',
		ua: 'Непідтверджений обліковий запис',
	},
	unverifiedMessage: {
		da: `Din konto er ikke verificeret.<br />Tjek din email for en verificeringsmail. Hvis du ikke kan finde den, så tjek dit spam filter eller bed om en ny.`,
		en: `Your account is unverified.<br />Check your email for a verification mail. If you cannot find it, then check your spam folder or request a new one.`,
		se: 'Ditt konto är overifierat.<br />Kontrollera din e-post efter ett verifieringsmeddelande. Om du inte kan hitta den, kontrollera din skräppostmapp eller begär en ny.',
		de: 'Ihr Konto ist nicht verifiziert.<br />Suchen Sie in Ihrem Posteingang nach einer Bestätigungsmail. Wenn Sie diese nicht finden können, überprüfen Sie Ihren Spam-Ordner oder fordern Sie eine neue an.',
		pl: 'Your account is unverified.<br />Check your email for a verification mail. If you cannot find it, then check your spam folder or request a new one.',
		ro: 'Contul dvs. nu este verificat.<br />Verificați-vă e-mailul pentru un e-mail de verificare. Dacă nu îl găsiți, verificați dosarul de spam sau solicitați unul nou.',
		ua: 'Ваш обліковий запис не підтверджено.<br />Перевірте свою електронну пошту на наявність листа з підтвердженням. Якщо ви не можете його знайти, перевірте папку зі спамом або надішліть запит на нову.',
	},
	unverifiedMailButton: {
		da: 'Send ny email',
		en: 'Send new email',
		se: 'Skicka nytt e-postmeddelande',
		de: 'Neue E-Mail senden',
		pl: 'Wyślij nowy e-mail',
		ro: 'Trimite e-mail nou',
		ua: 'Надіслати новий електронний лист',
	},


};

export {
	loginUiTexts
};