const gameUiTexts = {
	moduleOverview: {
		title: {
			da: 'Moduloversigt',
			en: 'Module overview',
			se: 'Modulöversikt',
			de: 'Zurücksetzen',
			pl: 'Zresetuj',
			ro: `Prezentare generală a modulului`,
			ua: `Скинути`,
			vi: `Tổng quan về mô-đun`,
			hu: `Modul áttekintése`,
			ru: `Обзор модуля`,
			et: 'Mooduli ülevaade'
		},
		unknownModulePopup: {
			text: {
				da: 'Fejl. Der er ikke noget data for dette modul.',
				en: 'Error. There is no data for this module.',
				se: 'Error. Det finns ingen data för denna modul.',
				de: `Fehler. Es gibt keine Daten für dieses Modul.`,
				pl: `Błąd. Brak danych dla tego modułu.`,
				ro: `Eroare. Nu există date pentru acest modul.`,
				ua: `Помилка. Даних для цього модуля немає.`,
				vi: `Lỗi. Không có dữ liệu cho mô-đun này.`,
				hu: `Hiba. Ehhez a modulhoz nincsenek adatok.`,
				ru: `Ошибка. Данных по этому модулю нет.`,
				et: 'Viga. Selle mooduli kohta andmed puuduvad.'
			}
		},
		moduleGroupLockedPopup: {
			text: {
				da: 'Du har ikke adgang til denne del endnu.',
				en: 'You do not have access to this part yet.',
				se: 'Du har inte tillgång till den här delen ännu.',
				de: `Sie haben noch keinen Zugriff auf diesen Teil.`,
				pl: `Nie masz jeszcze dostępu do tej części.`,
				ro: `Nu aveți încă acces la această parte.`,
				ua: `Ви ще не маєте доступу до цієї частини.`,
				vi: `Bạn chưa có quyền truy cập vào phần này.`,
				hu: `Még nincs hozzáférése ehhez a részhez.`,
				ru: `У вас пока нет доступа к этой части.`,
				et: 'Teil pole sellele osale veel juurdepääsu.'
			}
		},
		moduleLockedPopup: {
			text: {
				da: 'Du har ikke adgang til dette modul endnu.',
				en: 'You do not have access to this module yet.',
				se: 'Du har inte tillgång till denna modul ännu.',
				de: `Sie haben noch keinen Zugriff auf dieses Modul.`,
				pl: `Nie masz jeszcze dostępu do tego modułu.`,
				ro: `Nu aveți încă acces la acest modul.`,
				ua: `Ви ще не маєте доступу до цього модуля.`,
				vi: `Bạn chưa có quyền truy cập vào mô-đun này.`,
				hu: `Még nincs hozzáférése ehhez a modulhoz.`,
				ru: `У вас пока нет доступа к этому модулю.`,
				et: 'Teil pole sellele moodulile veel juurdepääsu.'
			}
		},
		modulePassedPopup: {
			text: {
				da: 'Du har allerede bestået dette modul.',
				en: 'You have already passed this module.',
				se: 'Du har redan klarat denna modul.',
				de: `Sie haben dieses Modul bereits bestanden.`,
				pl: `Już zdałeś ten moduł.`,
				ro: `Ați trecut deja acest modul.`,
				ua: `Ви вже пройшли цей модуль.`,
				vi: `Bạn đã vượt qua mô-đun này.`,
				hu: `Már letette ezt a modult.`,
				ru: `Вы уже прошли этот модуль.`,
				et: 'Olete selle mooduli juba läbinud.'
			}
		},
		moduleDisabledPopup: {
			text1: {
				da: `Du har lavet for mange fejl i modulet. Tal med kursuslederen om det videre forløb.
					<br /><br />Modulet er låst indtil %date%.`,
				en: `You have made too many errors in this module. Talk to your facilitator about how to proceed. 
					<br /><br />This module will be locked until %date%.`,
				se: `Du har gjort för många fel i den här modulen. Prata med din kontaktperson om hur du ska gå vidare.
					<br /><br />Den här modulen är låst till %date%.`,
				de: `Du hast in diesem Modul zu viele Fehler gemacht. Sprechen Sie mit Ihrem Moderator über das weitere Vorgehen.
					<br /><br />Dieses Modul wird bis zum %date% gesperrt.`,
				pl: `W tym module wystąpiło zbyt wiele błędów. Porozmawiaj ze swoim facylitatorem o tym, jak postępować. 
					<br /><br />Ten moduł będzie zablokowany do dnia %date%.`,
				ro: `Ați făcut prea multe erori în acest modul. Discutați cu facilitatorul despre cum să procedați.
					<br /><br />Acest modul va fi blocat până la %date%.`,
				ua: `Ви зробили забагато помилок у модулі. Поговоріть з керівником курсу про подальший процес.
					<br /><br />Модуль заблоковано до %date%.`,
				vi: `Bạn đã mắc quá nhiều lỗi trong mô-đun. Nói chuyện với người lãnh đạo khóa học về quá trình tiếp theo.
					<br /><br />Mô-đun bị khóa cho đến %date%.`,
				hu: `"Túl sok hibát vétett ebben a modulban. Beszélje meg facilitátorával, hogyan tovább. 
					<br /><br />Ez a modul %date%-ig zárolva lesz."`,
				ru: `"Вы допустили слишком много ошибок в этом модуле. Поговорите со своим координатором о том, как действовать дальше. 
					<br /><br />Этот модуль будет заблокирован до %date%."`,
				et: 'Olete selles moodulis teinud liiga palju vigu. Rääkige oma juhendajaga, kuidas edasi minna.<br /><br />See moodul on lukus kuni %date%.'
			},
			text2: {
				da: `Du har lavet for mange fejl i modulet. Tal med kursuslederen om det videre forløb.`,
				en: `You have made too many errors in this module. Talk to your facilitator about how to proceed.`,
				se: `Du har gjort för många fel i den här modulen. Prata med din kontaktperson om hur du ska gå vidare.`,
				de: `Du hast in diesem Modul zu viele Fehler gemacht. Sprechen Sie mit Ihrem Moderator über das weitere Vorgehen.`,
				pl: `W tym module wystąpiło zbyt wiele błędów. Porozmawiaj ze swoim facylitatorem o tym, jak postępować.`,
				ro: `Ați făcut prea multe erori în acest modul. Discutați cu facilitatorul despre cum să procedați.`,
				ua: `Ви зробили забагато помилок у модулі. Поговоріть з керівником курсу про подальший процес.`,
				vi: `Bạn đã mắc quá nhiều lỗi trong mô-đun. Nói chuyện với người lãnh đạo khóa học về quá trình tiếp theo.`,
				hu: `Túl sok hibát vétett ebben a modulban. Beszélje meg facilitátorával, hogyan tovább.`,
				ru: `Вы допустили слишком много ошибок в этом модуле. Поговорите со своим координатором о том, как действовать дальше.`,
				et: 'Olete selles moodulis teinud liiga palju vigu. Rääkige oma juhendajaga, kuidas edasi minna.'
			}
		}
	},

	next: {
		da: 'Næste',
		en: 'Next',
		se: 'Nästa',
		de: `Weiter`,
		pl: `Następny`,
		ro: `Următorul`,
		ua: `Продовжити`,
		vi: `Kế tiếp`,
		hu: `Tovább`,
		ru: `Следующий`,
		et: 'Edasi'
	},

	choose: {
		da: 'Vælg',
		en: 'Choose',
		se: 'Välj',
		de: 'Wählen',
		pl: 'Wybierz',
		ro: `Alege`,
		ua: 'вибрати',
		vi: `Lựa`,
		hu: `Választ`,
		ru: `Выбирать`,
		et: 'Vali'
	},

	ok: {
		da: 'OK',
		en: 'OK',
		se: 'OK',
		de: `OK`,
		pl: `OK`,
		ro: `OK`,
		ua: `OK`,
		vi: `OK`,
		hu: `OK`,
		ru: `OK`,
		et: 'OK'
	},

	close: {
		da: 'Luk',
		en: 'Close',
		se: 'Stänga',
		de: `Schließen`,
		pl: `Zamknij`,
		ro: `Închide`,
		ua: `Закрити`,
		vi: `Đóng`,
		hu: `Bezár`,
		ru: `Закрывать`,
		et: 'Sule'
	},

	chooseYourAnswer: {
		da: `Vælg dit svar`,
		en: `Choose your answer`,
		se: `Välj ditt svar`,
		de: `Wählen Sie Ihre Antwort`,
		pl: `Wybierz swoją odpowiedź`,
		ro: `Alegeți răspunsul dvs`,
		ua: `Виберіть відповідь`,
		vi: `Chọn câu trả lời của bạn`,
		hu: `Válassza ki a választ`,
		ru: `Выберите ответ`,
		et: 'Valige oma vastus'
	},
	chooseYourAction: {
		da: `Vælg hvad du gør`,
		en: `Choose what you do`,
		se: `Välj vad du gör`,
		de: `Wählen Sie, was Sie tun`,
		pl: `Wybierz co robisz`,
		ro: `Alege ce faci`,
		ua: `Вибирайте, що робитимете`,
		vi: `Chọn những gì bạn làm`,
		hu: `Válassza ki, mit csinál`,
		ru: `Выбирай, что делать`,
		et: 'Valige, mida teete'
	},

	spotItemsPopup: {
		result1: {
			title: {
				da: 'Ups!',
				en: 'Oops!',
				se: 'Hoppsan!',
				de: 'Hoppla!',
				pl: 'Ups!',
				ro: `Hopa!`,
				ua: 'Ой!',
				vi: `Rất tiếc!`,
				hu: `Hoppá!`,
				ru: `Упс!`,
				et: 'Oih!'
			},
			defaultText: {
				da: `Du fandt %selectedItems% ud af %numberOfCorrecItems%.`,
				en: 'You spotted %selectedItems% of %numberOfCorrecItems%.',
				se: 'Du hittade %selectedItems% av %numberOfCorrecItems%.',
				de: `Sie haben %selectedItems% von %numberOfCorrecItems% entdeckt.`,
				pl: `Znalazłeś %selectedItems% z %numberOfCorrecItems%.`,
				ro: `Ai găsit %selectedItems% din %numberOfCorrecItems%.`,
				ua: `Ви знайшли %selectedItems% з %numberOfCorrecItems%.`,
				vi: `Bạn đã tìm thấy %selectedItems% trong %numberOfCorrecItems%.`,
				hu: `%numberOfCorrecItems%-ból %selectedItems%-ot észlelt.`,
				ru: `Вы заметили %selectedItems% из %numberOfCorrecItems%.`,
				et: 'Märkasite %selectedItems%/%numberOfCorecItems%.'
			},
			conditionalTexts: [
				{
					conditions: [
						{conditionType: 'itemType', conditionValue: 'error'},
						{conditionType: 'multipleItems', conditionValue: true}
					],
					text: {
						da: `Du fandt %selectedItems% ud af %numberOfCorrecItems% fejl.`,
						en: 'You spotted %selectedItems% of %numberOfCorrecItems% errors.',
						se: 'Du hittade %selectedItems% av %numberOfCorrecItems% fel.',
						de: `Sie haben %selectedItems% von %numberOfCorrecItems% Fehlern entdeckt.`,
						pl: `Znalazłeś %selectedItems% z %numberOfCorrecItems% błędów.`,
						ro: `Ai găsit %selectedItems% din %numberOfCorrecItems% erori.`,
						ua: `Ви знайшли %selectedItems% з %numberOfCorrecItems% помилок.`,
						vi: `Bạn đã tìm thấy %selectedItems% ra khỏi lỗi %numberOfCorrecItems%.`,
						hu: `%numberOfCorrecItems%-ból %selectedItems% hibát észlelt.`,
						ru: `Вы обнаружили ошибки %selectedItems% из %numberOfCorrecItems%.`,
						et: 'Märkasite %selectedItems% %numberOfCorrecItems% vigadest.'
					}
				},
				{
					conditions: [
						{conditionType: 'itemType', conditionValue: 'error'},
						{conditionType: 'multipleItems', conditionValue: false}
					],
					text: {
						da: `Du fandt ikke fejlen.`,
						en: 'You missed the error.',
						se: 'Du hittade inte felet.',
						de: `Sie haben den Fehler übersehen.`,
						pl: `Przegapiłeś błąd.`,
						ro: `Ai ratat eroarea.`,
						ua: `Ви пропустили помилку.`,
						vi: `Bạn không tìm thấy lỗi.`,
						hu: `Kihagytad a hibát.`,
						ru: `Вы пропустили ошибку.`,
						et: 'Sa jäid veast ilma.'
					}
				},
			],
		},
		result2: {
			title: {
				da: 'Ikke dårligt!',
				en: 'Not bad!',
				se: 'Inte illa!',
				de: `Nicht schlecht!`,
				pl: `Nieźle!`,
				ro: `Nu e rău!`,
				ua: `Непогано!`,
				vi: `Không tồi!`,
				hu: `Nem rossz!`,
				ru: `Неплохо!`,
				et: 'Pole paha!'
			},
			defaultText: {
				da: `Du fandt %selectedItems% ud af %numberOfCorrecItems%.`,
				en: 'You spotted %selectedItems% of %numberOfCorrecItems%.',
				se: 'Du hittade %selectedItems% av %numberOfCorrecItems%.',
				de: `Sie haben %selectedItems% von %numberOfCorrecItems% entdeckt.`,
				pl: `Znalazłeś %selectedItems% z %numberOfCorrecItems%.`,
				ro: `Ai găsit %selectedItems% din %numberOfCorrecItems%.`,
				ua: `Ви знайшли %selectedItems% з %numberOfCorrecItems%.`,
				vi: `Bạn đã tìm thấy %selectedItems% trong %numberOfCorrecItems%.`,
				hu: `%numberOfCorrecItems%-ból %selectedItems%-ot észlelt.`,
				ru: `Вы заметили %selectedItems% из %numberOfCorrecItems%.`,
				et: 'Märkasite %selectedItems%/%numberOfCorecItems%.'
			},
			conditionalTexts: [
				{
					conditions: [
						{conditionType: 'itemType', conditionValue: 'error'},
						{conditionType: 'multipleItems', conditionValue: true}
					],
					text: {
						da: `Du fandt %selectedItems% ud af %numberOfCorrecItems% fejl.`,
						en: 'You spotted %selectedItems% of %numberOfCorrecItems% errors.',
						se: 'Du hittade %selectedItems% av %numberOfCorrecItems% fel.',
						de: `Sie haben %selectedItems% von %numberOfCorrecItems% Fehlern entdeckt.`,
						pl: `Znalazłeś %selectedItems% z %numberOfCorrecItems% błędów.`,
						ro: `Ai găsit %selectedItems% din %numberOfCorrecItems% erori.`,
						ua: `Ви знайшли %selectedItems% з %numberOfCorrecItems% помилок.`,
						vi: `Bạn đã tìm thấy %selectedItems% ra khỏi lỗi %numberOfCorrecItems%.`,
						hu: `%numberOfCorrecItems%-ból %selectedItems% hibát észlelt.`,
						ru: `Вы обнаружили ошибки %selectedItems% из %numberOfCorrecItems%.`,
						et: 'Märkasite %selectedItems% %numberOfCorrecItems% vigadest.'
					}
				},
			],
		},
		result3: {
			title: {
				da: 'Godt klaret!',
				en: 'Well done!',
				se: 'Bra jobbat!',
				de: `Gut gemacht!`,
				pl: `Dobra robota!`,
				ro: `Bravo!`,
				ua: `Молодець!`,
				vi: `Tốt!`,
				hu: `Szép munka!`,
				ru: `Отличная работа!`,
				et: 'Hästi tehtud!'
			},
			defaultText: {
				da: `Du fandt %selectedItems% ud af %numberOfCorrecItems%.`,
				en: 'You spotted %selectedItems% of %numberOfCorrecItems%.',
				se: 'Du hittade %selectedItems% av %numberOfCorrecItems%.',
				de: `Sie haben %selectedItems% von %numberOfCorrecItems% entdeckt.`,
				pl: `Znalazłeś %selectedItems% z %numberOfCorrecItems%.`,
				ro: `Ai găsit %selectedItems% din %numberOfCorrecItems%.`,
				ua: `Ви знайшли %selectedItems% з %numberOfCorrecItems%.`,
				vi: `Bạn đã tìm thấy %selectedItems% trong %numberOfCorrecItems%.`,
				hu: `%numberOfCorrecItems%-ból %selectedItems%-ot észlelt.`,
				ru: `Вы заметили %selectedItems% из %numberOfCorrecItems%.`,
				et: 'Märkasite %selectedItems%/%numberOfCorecItems%.'
			},
			conditionalTexts: [
				{
					conditions: [
						{conditionType: 'itemType', conditionValue: 'error'},
						{conditionType: 'multipleItems', conditionValue: true}
					],
					text: {
						da: 'Du fandt %selectedItems% ud af %numberOfCorrecItems% fejl.',
						en: 'You spotted %selectedItems% of %numberOfCorrecItems% errors.',
						se: 'Du hittade %selectedItems% av %numberOfCorrecItems% fel.',
						de: `Sie haben %selectedItems% von %numberOfCorrecItems% Fehlern entdeckt.`,
						pl: `Znalazłeś %selectedItems% z %numberOfCorrecItems% błędów.`,
						ro: `Ai găsit %selectedItems% din %numberOfCorrecItems% erori.`,
						ua: `Ви знайшли %selectedItems% з %numberOfCorrecItems% помилок.`,
						vi: `Bạn đã tìm thấy %selectedItems% ra khỏi lỗi %numberOfCorrecItems%.`,
						hu: `%numberOfCorrecItems%-ból %selectedItems% hibát észlelt.`,
						ru: `Вы обнаружили ошибки %selectedItems% из %numberOfCorrecItems%.`,
						et: 'Märkasite %selectedItems% %numberOfCorrecItems% vigadest.'
					}
				},
				{
					conditions: [
						{conditionType: 'itemType', conditionValue: 'error'},
						{conditionType: 'multipleItems', conditionValue: false}
					],
					text: {
						da: 'Du fandt fejlen.',
						en: 'You spotted the error.',
						se: 'Du hittade felet.',
						de: `Sie haben den Fehler entdeckt.`,
						pl: `Znalazłeś błąd.`,
						ro: `Ai găsit eroarea.`,
						ua: `Ви знайшли помилку.`,
						vi: `Bạn đã tìm thấy lỗi.`,
						hu: `Észrevetted a hibát.`,
						ru: `Вы заметили ошибку.`,
						et: 'Sa märkasid vea.'
					}
				},
			],
		},
	},

	survey: {
		chooseXOptions: {
			da: 'Vælg %X%',
			en: 'Choose %X%',
			se: 'Välj %X%',
			de: `Wählen Sie %X%`,
			pl: `Wybierz %X%`,
			ro: `Alege %X%`,
			ua: `Виберіть %X%`,
			vi: `Chọn %X%`,
			hu: `Válasszon %X%`,
			ru: `Выберите %X%`,
			et: 'Vali %X%'
		},
		chooseMaxXOptions: {
			da: 'Vælg op til %X%',
			en: 'Choose up to %X%',
			se: 'Välj upp till %X%',
			de: `Wählen Sie bis zu %X%`,
			pl: `Wybierz do %X%`,
			ro: `Alege până la %X%`,
			ua: `Виберіть до %X%`,
			vi: `Chọn tối đa %X%`,
			hu: `Válasszon legfeljebb %X%`,
			ru: `Выберите до %X%`,
			et: 'Valige kuni %X%'
		},
	},

	resultPopup: {
		title1: {
			da: 'Desværre!',
			en: 'Oh no!',
			se: 'Hoppsan!',
			de: `Oh nein!`,
			pl: `O nie!`,
			ro: `Oh nu!`,
			ua: `О ні!`,
			vi: `Tiếc!`,
			hu: `Óh ne!`,
			ru: `О, нет!`,
			et: 'Oh ei!'
		},
		title2: {
			da: 'Tillykke!',
			en: 'Congratulations!',
			se: 'Grattis!',
			de: `Glückwunsch!`,
			pl: `Gratulacje!`,
			ro: `Felicitări!`,
			ua: `Вітаємо!`,
			vi: `Chúc mừng!`,
			hu: `Gratulálunk!`,
			ru: `Поздравляем!`,
			et: 'Palju õnne!'
		},
		text1: {
			da: 'Du klarede det ikke',
			en: `You didn't make it`,
			se: `Du klarade det inte`,
			de: `Sie haben es nicht geschafft`,
			pl: `Nie udało ci się`,
			ro: `Nu ai reușit`,
			ua: `Вам не вдалося`,
			vi: `Bạn đã không làm được`,
			hu: `Nem sikerült`,
			ru: `Ты не успел`,
			et: 'Sa ei pääsenud'
		},
		text2: {
			da: 'Du klarede det',
			en: 'You made it',
			se: `Du gjorde det`,
			de: `Sie haben es geschafft`,
			pl: `Udało ci się`,
			ro: `Ai reușit`,
			ua: `Вам вдалося`,
			vi: `Bạn đã làm được điều đó`,
			hu: `Megcsináltad`,
			ru: `Ты сделал это`,
			et: 'Sa said hakkama'
		},
		text3: {
			da: `Du har gennemført træningen`,
			en: 'You have completed the training',
			se: `Du har genomfört utbildningen`,
			de: `Sie haben das Training abgeschlossen`,
			pl: `Ukończyłeś szkolenie`,
			ro: `Ai completat antrenamentul`,
			ua: `Ви завершили тренування`,
			vi: `Bạn đã hoàn thành khóa đào tạo`,
			hu: `Elvégezte a képzést`,
			ru: `Вы прошли обучение`,
			et: 'Olete koolituse läbinud'
		},
		tryAgain: {
			da: 'Tag modulet igen',
			en: 'Try the module again',
			se: 'Ta modulen igen',
			de: `Versuchen Sie das Modul erneut`,
			pl: `Spróbuj moduł ponownie`,
			ro: `Încearcă modulul din nou`,
			ua: `Спробуйте модуль знову`,
			vi: `Thi lại mô-đun`,
			hu: `Próbálja újra a modult`,
			ru: `Попробуйте модуль еще раз`,
			et: 'Proovige moodulit uuesti'
		},
		continue: {
			da: 'Videre til næste modul',
			en: 'Continue to next module',
			se: 'Till nästa modul',
			de: `Weiter zum nächsten Modul`,
			pl: `Przejdź do następnego modułu`,
			ro: `Continuă la următorul modul`,
			ua: `Продовжити до наступного модуля`,
			vi: `Sang mô-đun tiếp theo`,
			hu: `Tovább a következő modulhoz`,
			ru: `Перейти к следующему модулю`,
			et: 'Jätkake järgmise mooduliga'
		},
		backToModuleOverview: {
			da: 'Tilbage til hovedsiden',
			en: 'Back to main page',
			se: 'Tillbaka till huvudsidan',
			de: `Zurück zur Hauptseite`,
			pl: `Wróć do strony głównej`,
			ro: `Înapoi la pagina principală`,
			ua: `Повернутися на головну сторінку`,
			vi: `Quay lại trang chính`,
			hu: `Vissza a főoldalra`,
			ru: `Вернуться на главную страницу`,
			et: 'Tagasi pealehele'
		}
	},

	orderPopup: {
		title: {
			da: 'Du fik %correctAnswers%/%totalAnswers%.',
			en: `You got %correctAnswers%/%totalAnswers%.`,
			se: 'Du fick %correctAnswers%/%totalAnswers%.',
			de: `Sie haben %correctAnswers%/%totalAnswers%.`,
			pl: `Masz %correctAnswers%/%totalAnswers%.`,
			ro: `Ai obținut %correctAnswers%/%totalAnswers%.`,
			ua: `Ви отримали %correctAnswers%/%totalAnswers%.`,
			vi: `Bạn nhận được %correctAnswers%/%totalAnswers%.`,
			hu: `%correctAnswers%/%totalAnswers%.`,
			ru: `Вы получили %correctAnswers%/%totalAnswers%.`,
			et: 'Saite %correctAnswers%/%totalAnswers%.'
		},
		text: {
			da: 'Prøv igen!',
			en: `Try again!`,
			se: 'Försök igen!',
			de: `Versuchen Sie es noch einmal!`,
			pl: `Spróbuj ponownie!`,
			ro: `Încearcă din nou!`,
			ua: `Спробуйте ще раз!`,
			vi: `Vui lòng thử lại!`,
			hu: `Próbáld újra!`,
			ru: `Попробуйте еще раз!`,
			et: 'Proovi uuesti!'
		}
	},

	organizePopup: {
		text1: {
			da: 'Perfekt! Du havde 0 fejl.',
			en: `Perfect! You had 0 mistakes.`,
			se: `Perfekt! Du hade 0 misstag.`,
			de: `Perfekt! Sie haben 0 Fehler gemacht.`,
			pl: `Idealnie! Nie popełniłeś żadnych błędów.`,
			ro: `Perfect! Ai avut 0 greseli.`,
			ua: `Ідеально! У вас було 0 помилок.`,
			vi: `Hoàn hảo! Bạn không có lỗi nào.`,
			hu: `Tökéletes! 0 hibád volt.`,
			ru: `Отлично! У вас 0 ошибок.`,
			et: 'Täiuslik! Sul oli 0 viga.'
		},
		text2: {
			da: 'Fedt! Du havde kun 1 fejl.<br /><br />Her er den korrekte løsning:',
			en: `Nice! You only had 1 mistake.<br /><br />Here is the correct solution:`,
			se: `Trevlig! Du hade bara ett misstag.<br /><br />Här är den korrekta lösningen:`,
			de: `Super! Du hast nur 1 Fehler gemacht.<br /><br />Hier ist die richtige Lösung:`,
			pl: `Świetnie! Miałeś tylko 1 błąd.<br /><br />Oto poprawne rozwiązanie:`,
			ro: `Frumos! Ai avut o singură greșeală.<br /><br />Iată soluția corectă:`,
			ua: `приємно! У вас була лише 1 помилка.<br /><br />Ось правильне рішення:`,
			vi: `Tuyệt! Bạn chỉ mắc 1 lỗi.<br /><br />Đây là giải pháp đúng:`,
			hu: `Szép! Csak 1 hibája volt.<br /><br />Íme a helyes megoldás:`,
			ru: `Отлично! У вас была только 1 ошибка.<br /><br />Вот правильное решение:`,
			et: 'Tore! Teil oli ainult 1 viga.<br /><br />Siin on õige lahendus:'
		},
		text3: {
			da: 'Du havde %numberOfErrors% fejl.<br /><br />Her er den korrekte løsning:',
			en: `You had %numberOfErrors% mistakes.<br /><br />Here is the correct solution:`,
			se: `Du hade %numberOfErrors% misstag.<br /><br />Här är den korrekta lösningen:`,
			de: `Sie haben %numberOfErrors% Fehler gemacht.<br /><br />Hier ist die richtige Lösung:`,
			pl: `Wystąpiło %numberOfErrors% błędów.<br /><br />Oto prawidłowe rozwiązanie:`,
			ro: `Ați avut %numberOfErrors% greșeli.<br /><br />Iată soluția corectă:`,
			ua: `У вас було %numberOfErrors% помилок.<br /><br />Ось правильне рішення:`,
			vi: `Bạn đã mắc %numberOfErrors% lỗi.<br /><br />Đây là giải pháp đúng:`,
			hu: `%numberOfErrors% hibája volt.<br /><br />Íme a helyes megoldás:`,
			ru: `У вас было %numberOfErrors% ошибок.<br /><br />Вот правильное решение:`,
			et: 'Teil oli %numberOfErrors% viga.<br /><br />Siin on õige lahendus:'
		},
		text4: {
			da: 'Ups, du havde %numberOfErrors% fejl.<br /><br />Her er den korrekte løsning:',
			en: `Oops, you had %numberOfErrors% mistakes.<br /><br />Here is the correct solution:`,
			se: `Hoppsan, du hade %numberOfErrors% misstag.<br /><br />Här är den korrekta lösningen:`,
			de: `Hoppla, Sie haben %numberOfErrors% Fehler gemacht.<br /><br />Hier ist die richtige Lösung:`,
			pl: `Ups, wystąpiło %numberOfErrors% błędów.<br /><br />Oto prawidłowe rozwiązanie:`,
			ro: `Hopa, ați avut %numberOfErrors% greșeli.<br /><br />Iată soluția corectă:`,
			ua: `На жаль, у вас %numberOfErrors% помилок.<br /><br />Ось правильне рішення:`,
			vi: `Ồ, bạn đã mắc %numberOfErrors% lỗi.<br /><br />Đây là giải pháp đúng:`,
			hu: `Hoppá, %numberOfErrors% hibája volt.<br /><br />Íme a helyes megoldás:`,
			ru: `Упс, у вас было %numberOfErrors% ошибок.<br /><br />Вот правильное решение:`,
			et: 'Oih, teil oli %numberOfErrors% viga.<br /><br />Siin on õige lahendus:'
		},
	},

	loggedInAs: {
		da: 'Logget ind som',
		en: 'Logged in as',
		se: 'Inloggad som',
		de: 'Eingeloggt als',
		pl: 'Zalogowany jako',
		ro: 'Conectat ca',
		ua: 'Увійшов як',
		vi: 'Đăng nhập với tư cách',
		hu: 'Bejelentkezve',
		ru: 'Вошел в систему как',
		et: 'Sisse logitud kui'
	}
};

export {
	gameUiTexts
};