const taskUiTexts = {
	task: {
		da: 'Opgave',
		en: 'Task',
		se: `Uppgift`,
		de: `Aufgabe`,
		pl: `Zadanie`,
		ro: `Sarcină`,
		ua: `Завдання`,
	},
	taskId: {
		da: 'Opgave Id',
		en: 'Task Id',
		se: `Uppgifts-ID`,
		de: `Aufgaben-ID`,
		pl: `Identyfikator zadania`,
		ro: `ID activitate`,
		ua: `ID завдання`,
	},
	viewTask: {
		da: 'Se opgave',
		en: 'View task',
		se: `Visa uppgift`,
		de: `Zuweisung anzeigen`,
		pl: `Wyświetlanie przypisania`,
		ro: `Vizualizarea atribuirii`,
		ua: `Переглянути завдання`,
	},

	type: {
		da: 'Type',
		en: 'Type',
		se: `Typ`,
		de: `Art`,
		pl: `Typ`,
		ro: `Tip`,
		ua: `Тип`,
	},

	question: {
		da: 'Spørgsmål',
		en: 'Question',
		se: `Fråga`,
		de: `Frage`,
		pl: `Pytanie`,
		ro: `Întrebare`,
		ua: `Питання`,
	}
};

export {
	taskUiTexts
};