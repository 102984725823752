const redirectData = [
	{
		fromUrl: 'dcsafetytraining.dk',
		toUrl: 'https://traininggames.dk/dc', 
	},
	{
		fromUrl: 'iffhygienetraining.dk',
		toUrl: 'https://traininggames.dk/iff'
	}
];

export {redirectData};