const cpanelUiTexts = {
	/* Not authorized */
	notAuthorized: {
		da: 'Du har ikke adgang til denne side.',
		en: 'You are not authorized to view this page.'
	},


	/* Server name */
	demo: {
		da: 'demo',
		en: 'demo'
	},
	production: {
		da: 'produktion',
		en: 'production'
	},


	/* Header */
	controlPanel: {
		da: 'Kontrolpanel',
		en: 'Control panel',
	},


	/* Section navigation */
	companies: {
		da: 'Firmaer',
		en: 'Companies'
	},
	courses: {
		da: 'Kurser',
		en: 'Courses'
	},
	courseImages: {
		da: 'Kursusbilleder',
		en: 'Course images'
	},
	platform: {
		da: 'Platform',
		en: 'Platform'
	},


	/* Companies section */
	createNewCompany: {
		da: 'Opret nyt firma',
		en: 'Create new company'
	},
	companiesPage: {
		editCompany: {
			da: 'Rediger firma',
			en: 'Edit company'
		},
		info: {
			da: 'Info',
			en: 'Info'
		},
		departments: {
			da: 'Afdelinger',
			en: 'Departments'
		},
		admins: {
			da: 'Admins',
			en: 'Admins'
		},
		courses: {
			da: 'Kurser',
			en: 'Courses'
		},
		departmentType: {
			da: 'Navn på afdelinger',
			en: 'Name of departments'
		},
		addDepartment: {
			da: 'Tilføj afdeling',
			en: 'Add department'
		},
		allCourses: {
			da: 'Alle kurser',
			en: 'All courses'
		},
		missingName: {
			da: 'Navn mangler',
			en: 'Missing name'
		},
		email: {
			da: 'E-mail',
			en: 'E-mail'
		},
		addAdmin: {
			da: 'Tilføj admin',
			en: 'Add admin'
		},
		addAdminSendEmail: {
			da: 'Send velkomst e-mail',
			en: 'Send welcome e-mail'
		},
		missingEmail: {
			da: 'E-mail mangler',
			en: 'Missing e-mail'
		},
		invalidEmail: {
			da: 'Ugyldig e-mail',
			en: 'Invalid e-mail'
		},
		alreadyInvited: {
			da: 'Allerede tilføjet',
			en: 'Already added'
		},
		couldNotSendWelcomeEmail: {
			da: 'Admin tilføjet, men velkomst-email blev ikke sendt.',
			en: 'Admin added but welcome e-mail was not sent.'
		},
		removeAdmin: {
			da: 'Fjern admin',
			en: 'Remove admin'
		},
		removeAdminText: {
			da: 'Er du sikker på du vil fjerne %email% som admin?',
			en: 'Are you sure you want to remove %email% as admin?'
		},
		addCourse: {
			da: 'Tilføj kursus',
			en: 'Add course'
		},
		chooseCourseToAdd: {
			da: 'Vælg hvilket kursus, du vil give %company% adgang til.',
			en: 'Choose which course you want to give %company% access to.'
		},	
		couldNotGenerateUniqueId: {
			da: 'Kunne ikke generere unikt id',
			en: 'Could not generate unique id'
		},
		courseUrlNotUnique: {
			da: 'Firmaet har allerede et kursus med den url',
			en: 'The company already has a course with this url'
		},	
		removeCourse: {
			da: 'Fjern kursus',
			en: 'Remove course'
		},
		removeCourseText: {
			da: 'Er du sikker på du vil fjerne kurset %course% fra %company%?',
			en: 'Are you sure you want to remove the course %course% from %company%?'
		}
	},

	addCompanyPopup: {
		title: {
			da: 'Opret nyt firma',
			en: 'Create new company'
		},
		companyInfo: {
			da: 'Firmainfo',
			en: 'Company info'
		},
		departments: {
			da: 'Afdelinger',
			en: 'Departments'
		},
		departmentsLabel: {
			da: 'Type',
			en: 'Type'
		},
		departmentName: {
			da: 'Navn på første afdeling',
			en: 'Name of first department'
		},
		companyId: {
			da: 'ID / URL',
			en: 'ID / URL'
		},
		type: {
			da: 'Type',
			en: 'Type'
		},
		settings: {
			da: 'Indstillinger',
			en: 'Settings'
		},
		login: {
			da: 'Login',
			en: 'Login'
		},
		loginCombination: {
			da: 'Login-kombination',
			en: 'Login combination'
		},
		loginTypes: {
			da: 'Logintyper',
			en: 'Login types'
		},
		onlyInvitedCanLogin: {
			da: 'Kun inviterede kan logge ind',
			en: 'Only invited can log in'
		},
		onlyInvitedCanCreateUser: {
			da: 'Kun inviterede kan oprette en bruger',
			en: 'Only invited can create a user'
		},
		addLoginType: {
			da: 'Tilføj logintype',
			en: 'Add login type'
		},
		conditionGroups: {
			da: 'Betingelsesgrupper',
			en: 'Condition groups'
		},
		addConditionGroup: {
			da: 'Tilføj betingelsesgruppe',
			en: 'Add condition group'
		},
		or: {
			da: 'eller',
			en: 'or'
		},
		facilitatorLogin: {
			da: 'Facilitator-login',
			en: 'Facilitator login',
		},
		playerLogin: {
			da: 'Spiller-login',
			en: 'Player login'
		},
		sharedLogin: {
			da: 'Fælles login',
			en: 'Shared login'
		},

		playerInfo: {
			da: 'Spillerinfo',
			en: 'Player info'
		},
		enabledApis: {
			da: 'Aktive API\'er',
			en: 'Active API\'s'
		},
		otherConfig: {
			da: 'Andre indstillinger',
			en: 'Other config'
		},
		showDevToolsIfAdmin: {
			da: 'Vis dev-værktøjer hvis admin',
			en: 'Show dev tools if admin'
		},
		emptyField: {
			da: 'Ikke udfyldt',
			en: 'Empty field'
		},
		notUniqueId: {
			da: 'Id/url allerede i brug',
			en: 'Id/url already in use'
		},
		notUniqueUrl: {
			da: 'Url allerede i brug',
			en: 'Url already in use'
		},
		invalidName: {
			da: 'Ugyldigt navn',
			en: 'Invalid name'
		},
		invalidUrl: {
			da: 'Ugyldig URL',
			en: 'Invalid URL'
		},
		invalidRegex: {
			da: 'Ugyldig regex.',
			en: 'Invalid regex.'
		},
	},

	editCompanyPopup: {
		title: {
			da: 'Rediger firma',
			en: 'Edit company',
		},
		invalidDefaultLanguageId: {
			da: 'Ugyldigt default sprog',
			en: 'Invalid default language'
		}
	},

	deleteCompanyPopup: {
		title: {
			da: 'Slet firma',
			en: 'Delete company'
		},
		text: {
			da: 'Slet firmaet "%company%" og dets %numberOfUsers% brugere.<br /><br />Husk selv at slette firmaets auth tenant.',
			en: 'Delete the company "%company%" and its %numberOfUsers% users.<br /><br />Remember to manually delete the company\'s auth tenant.'
		}
	},

	checkCompanyPopup: {
		title: {
			da: 'Check firma',
			en: 'Check company'
		},
		intro: {
			da: 'Check indstillinger og indhold for firmaet %company%.',
			en: 'Check configurations and content for the company %company%.'
		},
		checking: {
			da: 'Checker ...',
			en: 'Checking ...'
		},
		companyDidNotPassCheck: {
			da: 'Firmaet klarede ikke checket.',
			en: 'The company did not pass the check.'
		},
		companyPassedCheck: {
			da: 'Firmaet klarede checket!',
			en: 'The company passed the check!'
		},
		loginWarningsErrors: {
			da: 'Fejl / advarsler for login-konfiguration',
			en: 'Errors / warnings for login-konfiguration'
		},
		departmentWarningsErrors: {
			da: 'Fejl / advarsler for afdelinger',
			en: 'Errors / warnings for departments'
		},
		noValidLanguages: {
			da: 'Firmaet har ikke nogen gyldige sprog.',
			en: 'The company has no valid languages.'
		},
		noCompanyConfig: {
			da: 'Firmaet har ikke nogen "company config".',
			en: 'The company does not have a "company config".'
		},
		noValidDepartmentLabelId: {
			da: 'Firmaet mangler en (gyldig) afdelingstype.',
			en: 'The company is missing a (valid) department type.'
		},
		noCryptoKey: {
			da: 'Firmaet mangler en crypto key.',
			en: 'The company is missing a crypto key.'
		},
		noLogoLoginImage: {
			da: 'Firmaet mangler et billede: logo-login.',
			en: 'The company is missing an image: logo-login.'
		},
		noCourses: {
			da: 'Firmaet har ingen kurser.',
			en: 'The company has no courses.'
		},
		noDepartments: {
			da: 'Firmaet har ingen afdelinger.',
			en: 'The company has no departments.'
		},
		noAdmins: {
			da: 'Firmaet har ingen admins.',
			en: 'The company has no admins.'
		},
		noLoginConfig: {
			da: 'Firmaet har ingen login-konfiguration.',
			en: 'The company has no login configuration.'
		},
		noTenantId: {
			da: 'Firmaet mangler et tenant id.',
			en: 'The company is missing a tenant id.'
		},
		noLoginTypes: {
			da: 'Firmaet har ingen login-metoder.',
			en: 'The company has no login methods.',
		},
		sharedLoginNoTypes: {
			da: 'Firmaet har "delt login" men ingen tilsvarende login-metoder.',
			en: 'The company has "shared login" but no corresponding login methods.'
		},
		noFacilitatorLogin: {
			da: 'Firmaet mangler en login-metode for facilitatorer.',
			en: 'The company is missing a login method for facilitators.'
		},
		noPlayerLogin: {
			da: 'Firmaet mangler en login-metode for spillere.',
			en: 'The company is missing a login method for players.'
		},
		invalidLoginTypeId: {
			da: 'En af login-metoder har et ugyldigt loginTypeId.',
			en: 'One of the login methods has an invalid loginTypeId.'
		},
		invalidLoginTypeRole: {
			da: 'Login-metoden %loginTypeId% har en ugyldig rolle (%role%).',
			en: 'The login method %loginTypeId% has an invalid role (%role%).' 
		},
		missingConditionGroup: {
			da: 'Login-metoden %loginTypeId% mangler en condition group med id %conditionGroupId%.',
			en: 'The login method %loginTypeId% is missing the condition group with id %conditionGroupId%.'
		},
		unknownConditionGroupIds: {
			da: 'Login-metoden %loginTypeId% har en eller flere condition groups med ukendt id: %conditionGroupIds%. De bliver ignoreret af koden.',
			en: 'The login method %loginTypeId% has one or more condition groups with unknown id: %conditionGroupIds%. They will be ignored by the code.',
		},
		multipleConditionGroupsNotAllowed: {
			da: 'Login-metoden %loginTypeId% må ikke have flere condition groups med id %conditionGroupId%.',
			en: 'The login method %loginTypeId% cannot have multiple condition groups with id %conditionGroupId%.'
		},
		unknownConditionIds: {
			da: 'Login-metoden %loginTypeId% har en condition group med id %conditionGroupId%, der har et eller flere conditions med ukendt id: %conditionIds%. De bliver ignoreret af koden.',
			en: 'The login method %loginTypeId% has a condition group with id %conditionGroupId% that has one or more condition with unknown id: %conditionIds%. They will be ignored by the code.',
		},
		noValidConditionIds: {
			da: 'Der er ingen gyldige conditions i condition group %conditionGroupId% for login-metoden %loginTypeId%.',
			en: 'There are no valid conditions in condition group %conditionGroupId% for the login method %loginTypeId%.'
		},
		requiredConditionMissing: {
			da: 'Der mangler en condition med id %conditionId% i condition group %conditionGroupId% for login-metoden %loginTypeId%.',
			en: 'A condition with id %conditionId% is missing in condition group %conditionGroupId% for the login method %loginTypeId%.'
		},
		invalidConditionOptionId: {
			da: 'Login-metoden %loginTypeId% har en condition group med id %conditionGroupId%, der har en condition med id %conditionIds% der har en ugyldigt option id: %optionId%.',
			en: 'The login method %loginTypeId% has a condition group with id %conditionGroupId% that has a condition with id %conditionIds% that has an invalid option id: %optionId%.',
		},
		untestedLoginConfig: {
			da: 'Denne login-konfiguration er ikke testet.',
			en: 'This login configuration has not been tested.'
		},
		noDepartmentName: {
			da: 'Afdeling %departmentId% har intet navn.',
			en: 'Department %departmentId% has no name.'
		},
		noDepartmentNameForLanguage: {
			da: 'Afdelingen %department% mangler navn for sproget %languageId%.',
			en: 'The department %department% is missing a name for the language %languageId%.'
		},
		noDepartmentCourses: {
			da: 'Afdelingen %department% har ikke adgang til nogen kurser.',
			en: 'The department %department% does not have access to any courses.'
		},
		translationTextMissing: {
			da: 'Der mangler tekster for %languageId% i filen %file%.',
			en: 'Texts for %languageId% are missing in the file %file%.'
		},
	},

	importPlayersPopup: {
		title: {
			da: 'Importer spillere',
			en: 'Import players'
		},
		text: {
			da: `Importer spillerdata fra et eksternt kursus til det tilsvarende kursus på platformen.`,
			en: 'Import player data from an external course to its corresponding course on the platform.'
		},
		readingFile: {
			da: `Læser fil ...`,
			en: `Reading file ...`
		},
		comparingData: {
			da: `Sammenligner scenariedata med kursusdata ...`,
			en: `Comparing scenario data with course data ...`
		},
		parsingPlayers: {
			da: 'Læser spillerdata ...',
			en: 'Parsing player data ...'
		},
		importingPlayers: {
			da: 'Importerer spillere ...',
			en: 'Importing players ...'
		},
		mapGamesToDepartments: {
			da: 'Knyt spil til afdelinger',
			en: 'Map games to departments'
		},
		chooseCourse: {
			da: 'Vælg kursus',
			en: 'Choose course'
		},
		chooseFile: {
			da: 'Vælg fil',
			en: 'Choose file'
		},
		chooseScenario: {
			da: 'Vælg scenarie',
			en: `Choose scenario`
		},
		chooseDepartment: {
			da: 'Vælg afdeling',
			en: 'Choose department'
		},
		scenario: {
			da: 'Scenarie',
			en: 'Scenario'
		},
		info: {
			da: 'Info',
			en: 'Info'
		},
		externalProjectName: {
			da: 'Eksternt projekt',
			en: 'External project'
		},
		scenarioAndCourseMatch: {
			da: 'Scenariedata og kursusdata matcher.',
			en: 'Scenario data and course data matches.'
		},
		gamesMappedToDepartments: {
			da: 'Spil er overført til afdelinger',
			en: 'Games mapped to departments'
		},
		validUsers: {
			da: 'Gyldige brugere (bliver importeret)',
			en: 'Valid users (will be imported)'
		},
		invalidUsers: {
			da: 'Ugyldige brugere (kan ikke importeres)',
			en: 'Invalid users (cannot be imported)'
		},
		validSurveys: {
			da: 'Gyldige surveys (bliver importeret)',
			en: 'Valid surveys (will be imported)'
		},
		invalidSurveys: {
			da: 'Ugyldige surveys (kan ikke importeres)',
			en: 'Invalid surveys (cannot be imported)'
		},
		errImportFileNotRead: {
			da: `Kunne ikke læse filen.`,
			en: `Could not read the file.`
		},
		errImportFileMissingEnv: {
			da: `Filen mangler 'env'.`,
			en: `The file is missing 'env'.`
		},
		errImportFileEnvMismatch: {
			da: `Filens env matcher ikke med dette env: %fileEnv% != %currentEnv%.`,
			en: `The file's env does not match with this env: %fileEnv% != %currentEnv%.`
		},
		errImportFileNoScenarios: {
			da: `Filen indeholder ikke nogen scenarier.`,
			en: `The file does not contain any scenarios.`
		},
		errImportFileNoPlayers: {
			da: `Filen indeholder ikke nogen spillere.`,
			en: `The file does not contain any players.`
		},
		errImportFileNoGames: {
			da: `Filen indeholder ikke nogen spil.`,
			en: `The file does not contain any games.`
		},
		errModuleGroupsMismatch: {
			da: `Det importerede scenarie har ikke samme antal modulgrupper som det valgte kursus: %scenario% != %course%.`,
			en: `The imported scenario does not have the same number of module groups as the selected course: %scenario% != %course%.`
		},
		errModulesMismatch: {
			da: `Modulgruppe #%moduleGroup%: Det importerede scenarie har ikke samme antal moduler i modulgruppen som det valgte kursus: %scenario% != %course%.`,
			en: `Module group #%moduleGroup%: The imported scenario does not have the same number of modules in the module group as the selected course: %scenario% != %course%.`
		},
		errTaskNumberMismatch: {
			da: `Modulgruppe #%moduleGroup%, modul #%module%: Det importerede scenarie har ikke samme antal opgaver i modulet som det valgte kursus: %scenario% != %course%.`,
			en: `Module group #%moduleGroup%, module #%module%: The imported scenario does not have the same number of tasks in the module as the selected course: %scenario% != %course%.`
		},
		errTaskTypeMismatch: {
			da: `Modulgruppe #%moduleGroup%, modul #%module%, opgave #%task%: Det importerede scenaries opgave er af en anden type end det valgte kursus' opgave: %scenario% != %course%.`,
			en: `Module group #%moduleGroup%, module #%module%, task #%task%: The imported scenario's task is of another type than the task of the selected course: %scenario% != %course%.`
		},
		errTaskItemsMismatch: {
			da: `Modulgruppe #%moduleGroup%, modul #%module% (%type%), opgave #%task%: Det importerede scenaries opgave ikke det samme antal items/options/categories som det valgte kursus' opgave.`,
			en: `Module group #%moduleGroup%, module #%module%, task #%task% (%type%): The imported scenario's task does not have the same number of items/options/categories as the task of the selected course.`
		},
		errNoPlayerGameId: {
			da: `Spilleren hører ikke til et spil.`,
			en: `Player is not connected to a game.`
		},
		errPlayerExists: {
			da: `Der eksisterer allerede en bruger med det id.`,
			en: `A user with this id already exists.`
		},
		errOneOrMoreInvalidPlayerModules: {
			da: `Spilleren har et eller flere ugyldige moduler / modulgrupper.`,
			en: `The player has one or more invalid modules / module groups.`
		}
	},

	/* Courses section */
	createNewCourse: {
		da: 'Opret nyt kursus',
		en: 'Create new course'
	},
	uploadNewCourse: {
		da: 'Upload kursus',
		en: 'Upload course'
	},
	updateTask: {
		da: 'Opdater opgave',
		en: 'Update task'
	},
	updateTaskAndClose: {
		da: 'Opdater og luk',
		en: 'Update and close'
	},
	editTask: {
		da: 'Rediger opgave',
		en: 'Edit task'
	},
	preview: {
		da: 'Forhåndsvisning',
		en: 'Preview'
	},
	edit: {
		da: 'Rediger',
		en: 'Edit'
	},
	marked: {
		da: 'Markeret',
		en: 'Marked'
	},
	textToSpeech: {
		da: 'Tekst til tale',
		en: 'Text to speech'
	},
	downloadCourse: {
		da: 'Download kursus',
		en: 'Download course'
	},
	uploadCourse: {
		da: 'Upload kursus',
		en: 'Upload course'
	},
	configuration: {
		da: 'Konfiguration',
		en: 'Configuration'
	},
	generateAudio: {
		da: 'Generer lyd',
		en: 'Generate audio'
	},

	coursesPage: {
		info: {
			da: 'Info',
			en: 'Info'
		},
		stats: {
			da: 'Stats',
			en: 'Stats'
		}
	},

	cleanUpPopup: {
		title: {
			da: 'Ryd op',
			en: 'Clean up'
		},
		orphanTasks: {
			da: 'Der er %X% forældreløse opgaver.',
			en: 'there are %X% orphan tasks.'
		},
		orphanModules: {
			da: 'Der er %X% forældreløse moduler.',
			en: 'there are %X% orphan modules.'
		}
	},

	courseType1: {
		info: {
			da: 'Info',
			en: 'Info'
		},
		modules: {
			da: 'Moduler',
			en: 'Modules'
		},
		moduleGroups: {
			da: 'Modulgrupper',
			en: 'Module groups'
		},
		checkCourse: {
			da: 'Check kursus',
			en: 'Check course'
		},
		deleteCourse: {
			da: 'Slet kursus',
			en: 'Delete course'
		},
		editCourse: {
			da: 'Rediger kursus',
			en: 'Edit course'
		},
		addModuleGroup: {
			da: 'Opret ny modulgruppe',
			en: 'Create new module group'
		},
		editModuleGroup: {
			da: 'Rediger modulgruppe',
			en: 'Edit module group'
		},
		addModule: {
			da: 'Opret nyt modul',
			en: 'Create new module'
		},
		copyTasksInModuleGroup: {
			da: 'Kopier alle opgaver fra modul',
			en: 'Copy all tasks from module'
		},
		editModule: {
			da: 'Rediger modul',
			en: 'Edit module'
		},
		noModuleGroup: {
			da: 'Ingen modulgruppe',
			en: 'No module group'
		},
		addTask: {
			da: 'Opret opgave',
			en: 'Create task'
		},
		goToPreview: {
			da: 'Forhåndsvis opgave',
			en: 'Preview Task'
		},
		goToTaskEdit: {
			da: 'Rediger opgave',
			en: 'Edit task'
		},
		addTaskAfterTaskNumber: {
			da: 'Indsæt efter opgave #',
			en: 'Insert after task #'
		},
		chooseAction: {
			da: 'Vælg handling',
			en: 'Choose action'
		},
		deleteMarkedTasks: {
			da: 'Slet markerede opgaver',
			en: 'Delete marked tasks'
		},
		deleteTasks: {
			da: 'Slet opgaver',
			en: 'Delete tasks'
		},
		confirmDeleteTask: {
			da: 'Er du sikker på, du vil slette den markerede opgave?',
			en: 'Are you sure you want to delete the marked task?'
		},
		confirmDeleteTasks: {
			da: 'Er du sikker på, du vil slette de markerede %X% opgaver?',
			en: 'Are you sure you want to delete the marked %X% tasks?'
		},
		copyMarkedTasks: {
			da: 'Kopier markede opgaver',
			en: 'Copy marked tasks'
		},
		chooseModuleToCopyMarkedTasksTo: {
			da: 'Vælg hvilket modul, du vil kopiere de markede opgaver til.',
			en: 'Choose which module you want to copy the marked tasks to.'
		},
		importTasks: {
			da: 'Importer opgaver',
			en: 'Import tasks'
		},
		importFromFile: {
			da: 'Importer fra fil',
			en: 'Import from file'
		},
		import: {
			da: 'Importer',
			en: 'Import'
		},
		tasks: {
			da: 'Opgaver',
			en: 'Tasks'
		},
		task: {
			da: 'Opgave',
			en: 'Task'
		},
		addEffect: {
			da: 'Tilføj effekt',
			en: 'Add effect'
		},
		addStep: {
			da: 'Tilføj step',
			en: 'Add step'
		},
		type: {
			da: 'Type',
			en: 'Type'
		},
		settings: {
			da: 'Indstillinger',
			en: 'Settings'
		},
		editTaskAdditionalFieldErr: {
			da: 'Kunne ikke tilføje ekstra felt: ',
			en: 'Could not add additional field: '
		},
		editTaskEffectTypeErr: {
			da: 'Ukendt effekt-type: ',
			en: 'Unkown effect type: '
		}

	},

	addCoursePopup: {
		title: {
			da: 'Opret nyt kursus',
			en: 'Create new course'
		},
		none: {
			da: 'Ingen',
			en: 'None'
		},
		newCompanyStyleId: {
			da: '+ Ny firmastil',
			en: '+ New company style'
		},
		newCompanySubStyleId: {
			da: '+ Ny firma sub-stil',
			en: '+ New company sub style'
		},
		emptyField: {
			da: 'Ikke udfyldt',
			en: 'Empty field'
		},
		invalidName: {
			da: 'Ugyldigt navn',
			en: 'Invalid name'
		},
		notUniqueId: {
			da: 'Id/url allerede i brug',
			en: 'Id/url already in use'
		},
		invalidCompanyStyleId: {
			da: 'Ugyldig firmastil',
			en: 'Invalid company style'
		},
		invalidCompanySubStyleId: {
			da: 'Ugyldig firma sub-stil',
			en: 'Invalid company sub style'
		}

	},

	uploadCoursePopup: {
		title: {
			da: 'Upload kursus',
			en: 'Upload course'
		},
		text: {
			da: `Vælg en zip-fil, du har downloaded fra %server%-serveren.
				<br /><br />Bemærk at kun nye billeder uploades, eksisterende billeder ignoreres.`,
			en: `Choose a zip file you have downloaded from the %server% server.
				<br /><br />Note that only new images will be uploaded, existing images will be ignored.`
		},
		deleteOldCourse: {
			da: 'Slet eksisterende kursus',
			en: 'Delete existing course'
		},
		chooseFile: {
			da: 'Vælg zip-fil',
			en: 'Choose zip file'
		},
		noInfoFile: {
			da: 'Zip-filen mangler en info-fil (info.json).',
			en: 'The zip file is missing an info file (info.json).'
		},
		couldNotReadInfoFile: {
			da: 'Kunne ikke læse info-filen. Check at den er formatteret korrekt.',
			en: 'Could not read the info file. Please check that it is formatted correctly.'
		},
		noCourseType: {
			da: 'Kursus-type er ukendt eller mangler.',
			en: 'Course type is unknown or missing.'
		},
		checkingFiles: {
			da: 'Læser zip-fil og tjekker indhold',
			en: 'Reading zip file and checking content'
		},
		filesOkSelectImages: {
			da: `Indhold ok. 
				<br /><br />Kursus blev downloaded %date%.
				<br /><br />Vælg de billeder du vil uploade / overskrive.`,
			en: `Content ok. 
				<br /><br />Course was downloaded %date%.
				<br /><br />Choose the images you want to upload / overwrite.`
		},
		newImages: {
			da: 'Nye billeder (upload)',
			en: 'New images (upload)'
		},
		existingImages: {
			da: 'Eksisterende billeder (overskriv)',
			en: 'Existing images (overwrite)'
		},
		uploadingCourse: {
			da: 'Uploader kursus',
			en: 'Uploading course'
		},
		courseUploaded: {
			da: 'Du har tilføjet et nyt kursus!',
			en: 'You have added a new course!'
		},
		feedbackTitle: {
			da: 'Resultat',
			en: 'Result'
		},
		feedbackMessagesCourse: {
			success: {
				da: 'Kursusdata uploaded.',
				en: 'Course data uploaded.'
			},
			error: {
				da: 'Kursusdata ikke uploaded.',
				en: 'Course data not uploaded.'
			}
		},
		feedbackMessagesAudio: {
				success: {
					da: 'Audiofiler uploaded.',
					en: 'Audio files uploaded.'
				},
				error: {
					da: 'Audiofiler ikke uploaded.',
					en: 'Audio files not uploaded.'
				}
		},
		feedbackMessagesImages: {
			success: {
				da: 'Billedfiler uploaded.',
				en: 'Image files uploaded.'
			},
			error: {
				da: 'Billedfiler ikke uploaded.',
				en: 'Image files not uploaded.'
			}
		},
		uploadedFiles: {
			da: 'Uploadede billedfiler',
			en: 'Uploaded image files'
		},
		skippedFiles: {
			da: 'Skippede billedefiler',
			en: 'Skipped image files'
		},
		timestampsDoNotMatch: {
			da: 'Timestamps matcher ikke.',
			en: 'Timestamps do not match.'
		},
		noModules: {
			da: 'Kurset mangler moduler / modulgrupper.',
			en: 'The course have no modules / module groups.'
		},
		noTasks: {
			da: 'Et eller flere moduler har ikke nogen opgaver.',
			en: 'One or more modules have no tasks.'
		},
		modulesDoNotMatch: {
			da: 'Moduler matcher ikke.',
			en: 'Modules do not match.'
		},
		tasksDoNotMatch: {
			da: 'Opgaver matcher ikke.',
			en: 'Tasks do not match',
		},
		courseWillBeDeletedWarning: {
			da: 'Advarsel: Den gamle version af dette kursus bliver automatisk slettet.',
			en: 'Warning: The old version of this course will automatically be deleted.'
		},
		courseExists: {
			da: 'Der eksisterer allerede et kursus med det id.',
			en: 'A course with the id already exists.'
		},

		modulesExist: {
			da: 'Der eksisterer allerede et eller flere moduler med samme id.',
			en: 'One or more modules with this id already exist.'
		},
		tasksExist: {
			da: 'Der eksisterer allerede en eller flere opgaver med samme id.',
			en: 'One or more tasks with this id already exist.'
		},
		audioFilesExist: {
			da: 'En eller flere af lydfilerne ligger allerede på serveren.',
			en: 'One or more of the audio files already exist on the server.'
		},
		audioFilePathsDoNotMatch: {
			da: 'En eller flere af lydfilernes paths matcher ikke med kursus/modul-data.',
			en: 'One or more of the audio file paths do not match the course/modules data.'
		},
		couldNotUploadAudioFiles: {
			da: 'Kunne ikke uploade lydfilerne.',
			en: 'Could not upload the audio files.'
		},
		couldNotUploadImageFiles: {
			da: 'Kunne ikke uploade billedfilerne.',
			en: 'Could not upload the image files.'
		}
	},
	checkCoursePopup: {
		title: {
			da: 'Check kursus',
			en: 'Check course'
		},
		intro: {
			da: 'Check indstillinger og indhold for kurset %course%.',
			en: 'Check configurations and content fo course %course%.'
		},
		checking: {
			da: 'Checker ...',
			en: 'Checking ...'
		},
		courseDidNotPassCheck: {
			da: 'Kurset klarede ikke checket.',
			en: 'The course did not pass the check.'
		},
		coursePassedCheck: {
			da: 'Kurset klarede checket!',
			en: 'The course passed the check.'
		},
		uiTextErrors: {
			da: 'Ui texts fejl',
			en: 'Ui texts errors'
		},
		generalWarnings: {
			da: 'Generelle fejl / advarsler',
			en: 'General errors / warnings'
		},
		moduleWarnings: {
			da: 'Fejl / advarsler for moduler',
			en: 'Errors / warnings for modules'
		},
		taskWarnings: {
			da: 'Fejl / advarsler for opgaver',
			en: 'Errors / warnings for tasks'
		},
		missingAudioFiles: {
			da: 'Manglende lydfiler',
			en: 'Missing audio files'
		},
		missingAudioVoice: {
			da: 'Der findes ingen text-to-speech data for sproget %languageId%',
			en: 'There exists no text-to-speech data for the language %languageId%'
		},
		uiTextsMissing: {
			da: 'Der mangler tekster for %languageId% i filen %file%.',
			en: 'Texts for %languageId% are missing in the file %file%.'
		},
		invalidCourseTypeId: {
			da: 'Manglende eller ugyldigt kursus-type: %courseTypeId%.',
			en: 'Missing or invalid course type: %courseTypeId%.'
		},
		invalidCourseStyleId: {
			da: 'Manglende eller ugyldigt kursus-stil: %courseStyleId%.',
			en: 'Missing or invalid course style: %courseStyleId%.'
		},
		missingCompanyStyleId: {
			da: 'Firma-stil mangler.',
			en: 'Company style is missing.'
		},
		missingUrl: {
			da: 'Kursus-url mangler.',
			en: 'Course url is missing.'
		},
		invalidProgressTypeId: {
			da: 'Manglende eller ugyldigt spilflow-type: %progressTypeId%.',
			en: 'Missing or invalid game flow type: %progressTypeId%.'
		},
		noModuleGroups: {
			da: 'Kurset har ingen modulgrupper.',
			en: 'The course has no module groups.'
		},
		emptyModuleGroups: {
			da: 'Følgende modulgrupper har ingen moduler',
			en: 'The following module groups have no modules'
		},
		allModuleGroupsLocked: {
			da: 'Alle modulgrupper er låste fra start.',
			en: 'All module groups are locked from the beginning.'
		},
		invalidModuleGroupRequirements: {
			da: 'Modulgruppen %moduleGroup% kræver en eller flere ugyldige modulgrupper.',
			en: 'The module group %moduleGroup% requires one or more invalid module groups.'
		},
		noInitialModuleGroup: {
			da: 'Kurset har ikke en gyldig første modulgruppe.',
			en: 'The course does not have a valid initial module group.'
		},
		invalidNextModuleGroup: {
			da: 'Modulgruppen %moduleGroup% peger ikke på en gyldig næste modulgruppe.',
			en: 'The module group %moduleGroup% does not point to a valid next module group.'
		},
		invalidModuleGroupExpiration: {
			da: 'Modulgruppen %moduleGroup% udløber efter et ikke-gyldigt antal måneder.',
			en: 'The module group %moduleGroup% expires after a non-valid number of months.'
		},
		invalidModuleGroupWaitingMonths: {
			da: 'Modulgruppen %moduleGroup% udløber efter %expirationMonths% måneder men man kan først få adgang til næste modulgruppe efter %waitingMonths% måneder.',
			en: 'The module group %moduleGroup% expires after %expirationMonths% months but the waiting period for the next module group is %waitingMonths% months.',
		},
		moduleGroupCycleDoesNotIncludeAllModuleGroups: {
			da: 'Modulgruppe-cyklussen inkluderer ikke alle modulgrupper.',
			en: 'The modulegroup cyclus does not include all module groups.'
		},
		noLanguages: {
			da: 'Kurset har ikke nogen sprog.',
			en: 'The course have no languages.'
		},
		invalidDefaultLanguage: {
			da: 'Manglende eller ugyldigt default sprog: %defaultLanguageId%.',
			en: 'Missing or invalid default language: %defaultLanguageId%.'
		},
		noCourseNameForLanguage: {
			da: 'Kursusnavn mangler for sproget %languageId%.',
			en: 'Course name missing for the language %languageId%.'
		},
		courseHasNoThumbnail: {
			da: 'Der er ikke angivet nogen thumbnail for kurset',
			en: 'No thumbnail has been provided for the course'
		},
		courseHasNoThumbnailSmall: {
			da: 'Der er ikke angivet nogen lille thumbnail for kurset',
			en: 'No small thumbnail has been provided for the course'
		},
		noModuleGroupNameForLanguage: {
			da: 'Modulgruppen %moduleGroup% mangler et navn for sproget %languageId%.',
			en: 'The module group %moduleGroup% is missing a name for the language %languageId%.'
		},
		moduleIdWithoutData: {
			da: 'Der er ikke data for modulet med id %moduleId% (modulgruppe %moduleGroup%).',
			en: 'There is no data for the module with id %moduleId% (module group %moduleGroup%).'
		},
		allModulesLocked: {
			da: 'Alle moduler i modulgruppe %moduleGroup% er låste fra start.',
			en: 'All modules in module group %moduleGroup% are locked from the beginning.'
		},
		moduleHasNoTasks: {
			da: 'Modulet %module% har ingen opgaver.',
			en: 'The module %module% has no tasks.'
		},
		noModuleNameForLanguage: {
			da: 'Modulet %module% mangler et navn for sproget %languageId%.',
			en: 'The module %module% is missing a name for the language %languageId%.'
		},
		noTextToSpeechForLanguage: {
			da: 'Text-to-speech er ikke genereret for sproget %languageId% for module %module%.',
			en: 'Text-to-speech has not been generated for the language %languageId% for %module%.'
		},
		moduleUpdatedSinceTextToSpeech: {
			da: 'Modulet %module% er blevet opdateret efter Text-to-speech blevet genereret for sproget %languageId%.',
			en: 'The module %module% has been updated after Text-to-speech was generated for the language %languageId%.'
		},
		invalidStarType: {
			da: 'Modul %module% har en ugyldig stjerne-type: %starType%.',
			en: 'Module %module% has an invalid star type: %starType%.'
		},
		starsToPassIsLargerThanMaxStars: {
			da: 'I module %module% er antal stjerner krævet for at bestå (%starsToPass%) større end maks mulige antal stjerner (%maxStars%).',
			en: 'In module %module% the number of stars to pass (%starsToPass%) is larger than the max possible number of stars (%maxStars%).'
		},
		maxStarsPointStepsMismatch: {
			da: 'I modul %module% matcher antallet af max stjerner (%starsToPass%) ikke med antallet af point-skridt (%pointSteps%).',
			en: 'In module %module% the number of max stjerner (%starsToPass%) does not match the number of point steps (%pointSteps%).',
		},
		invalidNumberOfFails: {
			da: 'I modul %module% er antal fejl, der trigger en modul-lås, ugyldigt: %numberOfFails%.',
			en: 'In module %module%, the number of fails that trigger a module-lock is invalid: %numberOfFails%.'
		},
		invalidNumberOfDisabledDays: {
			da: 'I modul %module% er antal dage, modulet kan være låst, ugyldigt: %numberOfDisabledDays%.',
			en: 'In module %module% the number of days the module can be locked is invalid: %numberOfDisabledDays%.',
		},
		taskIdWithoutData: {
			da: 'Modul %module%: Der er ikke data for opgaven med id %taskId%',
			en: 'Module %module%: There is no data for the task with id %taskId%.'
		},
		taskWithInvalidType: {
			da: 'Modul %module%: Opgave %taskId% har en ugyldig type: %type%.',
			en: 'Module %module%: Task %taskId% has an invalid type: %type%.'
		},
		containerWithoutId: {
			da: 'Modul %module%: Opgave %taskId% har en container uden id.',
			en: 'Module %module%: Task %taskId% has a container without id.'
		},
		containerDuplicateId: {
			da: 'Modul %module%: Opgave %taskId% har flere containers med samme id.',
			en: 'Module %module%: Task %taskId% has multiple containers with duplicate id.'
		},
		unknownMainContainerId: {
			da: 'Modul %module%: Hoved beholder id for beholder %containerId% matcher ingen hoved beholdere, i opgave %taskId%.',
			en: 'Module %module%: Maincontainer id for container %containerId% matches no main container, in task %taskId%.'
		},
		unknownFinalItemId: {
			da: 'Modul %module%: Opgave %taskId%. Endelig item ID (%itemId%) for beholder (%containerId%) matcher ikke nogen items.',
			en: 'Module %module%: Task %taskId%. Final item ID (%itemId%) for container (%containerId%) does not match any items.'
		},
		itemNoImage: {
			da: 'Modul %module%: Opgave %taskId% har item (%itemId%) uden billede.',
			en: 'Module %module%: Task %taskId% has item (%itemId%) without image.'
		},
		itemDuplicateId: {
			da: 'Modul %module%: Opgave %taskId% har flere items med samme id.',
			en: 'Module %module%: Task %taskId% has multiple items with duplicate id.'
		},
		noContainerId: {
			da: 'Modul %module%: Opgave %taskId%. Item (%itemId%) har ikke nogen beholder ID.',
			en: 'Module %module%: Task %taskId%. Item (%itemId%) does not have any container ID.'
		},
		unknownCorrectContainerId: {
			da: 'Modul %module%: Opgave %taskId%. Korrekt beholder id (%containerId%) matcher ikke nogen beholdere.',
			en: 'Module %module%: Task %taskId%. Correct container id (%containerId% does not match any container.'
		},
		unknownContainerId: {
			da: 'Modul %module%: Opgave %taskId%. Beholder id i item (%itemId%), matcher ikke nogen beholdere.',
			en: 'Module %module%: Task %taskId%. Container id in item (%itemId%), matches no container.'
		},
		itemWithoutId: {
			da: 'Modul %module%: Opgave %taskId% har en item uden id.',
			en: 'Module %module%: Task %taskId% has a item without id.'
		},
		noInitialContainer: {
			da: 'Modul %module%: Opgave %taskId% har ingen initial container.',
			en: 'Module %module%: Task %taskId% has no initial container.'
		},
		multipleInitialContainers: {
			da: 'Modul %module%: Opgave %taskId% har flere initial containers.',
			en: 'Module %module%: Task %taskId% has multiple initial container.'
		},
		mainContainerDuplicateId: {
			da: 'Modul %module%: Opgave %taskId% har flere main containers med samme id.',
			en: 'Module %module%: Task %taskId% has multiple main containers with duplicate id.'
		},
		taskHasNoText: {
			da: 'Modul %module%: Opgave %taskId% (%type%) har ingen tekst.',
			en: 'Module %module%: Task %taskId% (%type%) has no text.'
		},
		noTaskTextForLanguage: {
			da: 'Modul %module%: Opgave %taskId% (%type%) mangler text for sproget %languageId%.',
			en: 'Module %module%: Task %taskId% (%type%) is missing text for the language %languageId%.'
		},
		noTaskTitleForLanguage: {
			da: 'Modul %module%: Opgave %taskId% (%type%) mangler titel for sproget %languageId%.',
			en: 'Module %module%: Task %taskId% (%type%) is missing title for the language %languageId%.'
		},
		taskHasNoOptions: {
			da: 'Modul %module%: Opgave %taskId% (%type%) har ingen svarmuligheder.',
			en: 'Module %module%: Task %taskId% (%type%) has no options.'
		},
		taskHasDuplicateItemIds: {
			da: 'Modul %module%: Opgave %taskId% (%type%) indeholder duplikeret data i %itemType% for %prop%',
			en: 'Module %module%: Task %taskId% (%type%) contains duplicate data in %itemType% for %prop%'
		},
		taskHasNoCorrectOptions: {
			da: 'Modul %module%: Opgave %taskId% (%type%) har ingen korrekte svarmuligheder.',
			en: 'Module %module%: Task %taskId% (%type%) has no correct options.'
		},
		taskHasInvalidNumberOfOptionsToSelect: {
			da: 'Modul %module%: I opgave %taskId% (%type%, dilemma) er antallet af svar, man skal vælge, ugyldigt.',
			en: 'Module %module%: In task %taskId% (%type%, dilemma) the number of options to select is invalid.'
		},
		taskHasMinMaxOptionsMismatch: {
			da: 'Modul %module%: I opgave %taskId% (%type%) er max antal svar mindre en min antal svar.',
			en: 'Module %module%: In task %taskId% (%type%) the number of max answers is less than the number of min anwers.'
		},
		taskMissingShortText: {
			da: 'Modul %module%: Opgave %taskId% (%type%) mangler en kort tekst.',
			en: 'Module %module%: Task %taskId% (%type%) is missing a short text.'
		},
		taskMissingShortTextForLanguage: {
			da: 'Modul %module%: Opgave %taskId% (%type%) mangler kort tekst for sproget %languageId%.',
			en: 'Module %module%: Task %taskId% (%type%) is missing a short text for the language %languageId%.'
		},
		taskOptionMissingTextForLangauge: {
			da: 'Modul %module%: I opgave %taskId% (%type%) mangler svarmulighed #%optionNumber% tekst for sproget %languageId%.',
			en: 'Module %module%: In task %taskId% (%type%) option #%optionNumber% is missing text for the language %languageId%.'
		},
		taskWithSubtypeHasNoLayout: {
			da: 'Modul %module%: Opgave %taskId% (%type%, %subtype%) has ikke noget layout.',
			en: 'Module %module%: Task %taskId% (%type%, %subtype%) has no layout.'
		},
		taskWithSubtypeOptionHasNoImage: {
			da: 'Modul %module%: I opgave %taskId% (%type%, %subtype%) har svarmulighed #%optionNumber% ikke noget billede (%imageName%).',
			en: 'Module %module%: In task %taskId% (%type%, %subtype%) the option #%optionNumber% has no image (%imageName%).'
		},
		taskHasNoItemsCategories: {
			da: 'Modul %module%: Opgave %taskId% (%type%) har ingen items og/eller kategorier.',
			en: 'Module %module%: Task %taskId% (%type%) has no items and/or categories.'
		},
		taskHasNoSteps: {
			da: 'Modul %module%: Opgave %taskId% (%type%) har ingen steps.',
			en: 'Module %module%: Task %taskId% (%type%) has no steps.'
		},
		taskHasNoFinalSteps: {
			da: 'Modul %module%: Opgave %taskId% (%type%) har ingen `final` steps.',
			en: 'Module %module%: Task %taskId% (%type%) has no `final` steps.'
		},
		taskHasNoCharacter: {
			da: 'Modul %module%: Opgave %taskId% (%type%) har ingen character.',
			en: 'Module %module%: Task %taskId% (%type%) has no  character.'
		},
		taskStepHasInvalidNextStepId: {
			da: 'Modul %module%: I opgave %taskId% (%type%) har step #%stepNumber% et ugyldigt "next step id".',
			en: 'Module %module%: In task %taskId% (%type%) the step #%stepNumber% has an invalid "next step id".'
		},
		taskStepOptionHasInvalidNextStepId: {
			da: 'Modul %module%: I opgave %taskId% (%type%) har step #%stepNumber% option #%optionNumber% et ugyldigt "next step id".',
			en: 'Module %module%: In task %taskId% (%type%) the step #%stepNumber% option #%optionNumber% has an invalid "next step id".'
		},
		taskOrderItemHasInvalidCorrectOrderNumbers: {
			da: 'Modul %module%: I opgave %taskId% (%type%) har item #%itemNumber% en eller flere ugyldige rækkefølge-numre.',
			en: 'Module %module%: In task %taskId% (%type%) item #%itemNumber% has one or more invalid order numbers.'
		},
		taskSpotItemHasInvalidSize: {
			da: 'Modul %module%: I opgave %taskId% (%type%) har item #%itemNumber% en ugyldig størrelse (width/height skal være mindst 2).',
			en: 'Module %module%: In task %taskId% (%type%) item #%itemNumber% has an invalid size (width/height must be minimum 2).'
		},
		taskSpotItemHasInvalidPosition: {
			da: 'Modul %module%: I opgave %taskId% (%type%) har item #%itemNumber% en ugyldig position.',
			en: 'Module %module%: In task %taskId% (%type%) item #%itemNumber% has an invalid position.'
		},
		taskSortItemHasInvalidCategoryId: {
			da: 'Modul %module%: I opgave %taskId% (%type%) har item #%itemNumber% en eller flere ugyldige kategori-id\'er',
			en: 'Module %module%: In task %taskId% (%type%) item #%itemNumber% has one or more invalid cateogory ids.'
		},
		noCorrectItems: {
			da: 'Modul %module%: I opgave %taskId% (%type%) er ingen items correkte.',
			en: 'Module %module%: In task %taskId% (%type%) no items are correct.'
		},
		noEffectType: {
			da: 'Modul %module%: I opgave %taskId% (%type%) mangler en effekt for svarmulighed/item #%optionNumber% en type.',
			en: 'Module %module%: In task %taskId% (%type%) an effect for option/item #%optionNumber% is missing a type.'
		},
		noEffectText: {
			da: 'Modul %module%: I opgave %taskId% (%type%) mangler en effekt (%eType%) for svarmulighed/item #%optionNumber% tekst.',
			en: 'Module %module%: In task %taskId% (%type%) an effect (%eType%) for option/item #%optionNumber% is missing text.'
		},
		noEffectTitleOrText: {
			da: 'Modul %module%: I opgave %taskId% (%type%) mangler en effekt (%eType%) for svarmulighed/item #%optionNumber% tekst/titel.',
			en: 'Module %module%: In task %taskId% (%type%) an effect (%eType%) for option/item #%optionNumber% is missing text/title.'
		},
		noEffectTextForLanguageId: {
			da: 'Modul %module%: I opgave %taskId% (%type%) mangler en effekt (%eType%) for svarmulighed/item #%optionNumber% tekst for sproget %languageId%.',
			en: 'Module %module%: In task %taskId% (%type%) an effect (%eType%) for option/item #%optionNumber% is missing text for the language %languageId%.'
		},
		noEffectTitleForLanguageId: {
			da: 'Modul %module%: I opgave %taskId% (%type%) mangler en effekt (%eType%) for svarmulighed/item #%optionNumber% titel for sproget %languageId%.',
			en: 'Module %module%: In task %taskId% (%type%) an effect (%eType%) for option/item #%optionNumber% is missing title for the language %languageId%.'
		},
		invalidEffectPoints: {
			da: 'Modul %module%: I opgave %taskId% (%type%) giver en effekt (%eType%) for svarmulighed/item #%optionNumber% et ugyldigt antal point.',
			en: 'Module %module%: In task %taskId% (%type%) an effect (%eType%) for option/item #%optionNumber% is giving an invalid number of points.'
		}
	},
	deleteCoursePopup: {
		title: {
			da: 'Slet kursus',
			en: 'Delete course'
		},
		text: {
			da: `Er du sikker på, du vil slettet kurset "%courseName%" og alle dets moduler, opgaver og lydfiler?
				<br /><br />Bemærk, at denne handling ikke sletter nogen billeder fra Firebase Storage, da de deles af alle kurser af denne layout-type.
				<br /><br />Denne handling kan ikke gøres om!`,
			en: `Are you sure, you want to delete the course "%courseName%" and all its modules, tasks and audio files? 
				<br /><br />Note that this action will not delete any images in Firebase Storage as they are shared by all courses of this layout type.
				<br /><br />This action cannot be undone!`
		},
		warning: {
			da: `Advarsel! Kurset "%courseName%" tilhører følgende firmaer: %companies%.
				<br /><br />Er du sikker på, du vil slette kurset?`,
			en: `Warning! This course "%courseName%" is assigned to the following companies: %companies%.
				<br /><br />Are you sure you want to delete the course?`
		},
		removeCourseFromCompanies: {
			da: `Fjern kursus fra firmaer`,
			en: `Remove course from companies`
		},
		partialSuccess: {
			da: 'Kursusdata blev slettet, men lydfilerne kunne ikke slettes. Dette skal gøres manuelt fra mappen %audioFilesFolder%.',
			da: 'Course data was delete from databse, but the audio files could not be deleted. This must be done manually from the folder %audioFilesFolder%.'
		}
	},
	downloadCoursePopup: {
		title: {
			da: 'Download kursusdata',
			en: 'Download course data'
		},
		text: {
			da: `Download en zip-fil der indeholder alt data for kurset, dets moduler og deres opgaver (database), 
				default-billeder for kursets layout-type (storage) og specifikke billeder for kursets firma-type (storage).
				<br /><br />Denne fil kan uploades til %environment%-serveren for at tilføje kurset.`,
			en: `Download a zip file containing all data for this course, its modules and their tasks (database),
			 	default images for the layout type of the course (storage) and specific images for the company type of the 
				course (storage).
				<br /><br />This file can be uploaded to the %environment%-server to add the course.`
		},
		warningNoModuleData: {
			da: 'Kunne ikke finde data for modul med id %moduleId%.',
			en: 'Could not find data for module with id %moduleId%.'
		},
		warningNoTasksInModule: {
			da: 'Modulet med id %moduleId% har ingen opgaver.',
			en: 'The module with id %moduleId% has no tasks.'
		},
		warningNoTaskData: {
			da: 'Kunne ikke finde data for opgave med id %taskId%.',
			en: 'Could not find data for task with id %taskId%.'
		},
		warningNoAudioFiles: {
			da: 'Kunne ikke finde lydfiler for modul med id %moduleId%.',
			en: 'Could not find audio files for module with id %moduleId%.'
		}
	},


	/* Course images section */
	courseStylesPage: {
		styleId: {
			da: 'Id',
			en: 'Id'
		},
		styleName: {
			da: 'Navn',
			en: 'Name'
		},
		courseType: {
			da: 'Kursustype',
			en: 'Course type'
		}
	},
	popupDownloadImages: {
		title: {
			da: 'Download billeder',
			en: 'Download images'
		},
		text: {
			da: 'Download alle billeder i mappen "%folder%".',
			en: 'Download all images in the folder "%folder%".'
		},
		noFolder: {
			da: 'Kunne ikke finde data for mappen.',
			en: 'Could not find data for the folder.'
		},
		noImages: {
			da: 'Mappen indeholder ingen billeder.',
			en: 'The folder does not contain any images.'
		}
	},
	popupUploadImages: {
		title: {
			da: 'Upload billeder',
			en: 'Upload images'
		},
		text: {
			da: 'Upload billeder til mappen "%folder%".',
			en: 'Upload images to the folder "%folder%".'
		},
		chooseFile: {
			da: 'Vælg zip-fil',
			en: 'Choose zip file'
		},
		chosenFile: {
			da: 'Valgt fil',
			en: 'Chosen file'
		},
		readFile: {
			da: 'Læs fil',
			en: 'Read file'
		},
		readingFile: {
			da: 'Læser fil',
			en: 'Reading file'
		},
		newImages: {
			da: 'Nye billeder',
			en: 'New images'
		},
		existingImages: {
			da: 'Eksisterende billeder',
			en: 'Existing images'
		},
		selectImages: {
			da: 'Vælg hvilke billeder du vil uploade. Hvis du uploader et eksisterende billede, overskriver du det gamle.',
			en: 'Choose which images to upload. If you upload an existing image, it will replace the old.'
		},
		updated: {
			da: 'Sidst opdateret',
			en: 'Last updated'
		},
		uploadSelectedImages: {
			da: 'Upload valgte billeder',
			en: 'Upload selected images'
		},
		uploadingImages: {
			da: 'Uploader billeder',
			en: 'Uploading images'
		},
		noImagesSelected: {
			da: 'Du har ikke valgt nogle billeder til upload.',
			en: 'You have not selected any images for upload.'
		},
		imagesUploaded: {
			da: 'Billederne blev uploaded.',
			en: 'The images were uploaded.'
		}
	},

	/* Platform section */
	platformConfigPage: {
		contactFormRecipients: {
			da: 'Kontakformular - modtagere',
			en: 'Contact form - recipients'
		},
		addRecipient: {
			da: 'Tilføj modtager',
			en: 'Add recipient'
		}
	},
};

export {
	cpanelUiTexts
};