const generalUiTexts = {
	title: {
		da: 'Training Games',
		en: 'Training Games',
		se: 'Training Games',
		de: 'Training Games',
		pl: 'Training Games',
		ro: 'Training Games',
		ua: 'Training Games'
	},
	subtitle: {
		da: 'Spilbaseret læring & træning',
		en: 'Game based learning & training',
		se: 'Spelbaserat lärande och träning',
		de: 'Spielbasiertes Lernen und Training',
		pl: 'Nauka i trening oparty na grach',
		ro: 'Învățare și antrenament bazat pe joc',
		ua: 'Навчання та тренування на основі гри'
	},
	loading: {
		da: 'Loader',
		en: 'Loading',
		se: 'Läser in',
		de: `Wird geladen`,
		pl: `Ładowanie`,
		ro: `Se încarcă`,
		ua: `Навантажувач`

	}
};

export {
	generalUiTexts
};