import React from 'react';
import PropTypes from 'prop-types';
import { getText } from 'helpers/text-helper';
import './platform-title.scss';

const PlatformTitle = ({type, languageId}) => {

	return (
		<div className={'PlatformTitle' + (type ? ' ' + type : '')}>
			<div className='PlatformTitle-trainingGamesLogo'/>
			<span>{getText('general_title', languageId)}</span>
		</div>
	);
};

PlatformTitle.propTypes = {
	type: PropTypes.string,
	languageId: PropTypes.string.isRequired,
};

export default PlatformTitle;